import React, { useState } from 'react';
import { CCard, CCol, CRow, CCardHeader, CCardFooter, CCardBody } from "@coreui/react";
import MUIDataTable from "mui-datatables";
import { ThemeProvider } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { Tab, Tabs } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const muiCache = createCache({
    key: "mui-datatables",
    prepend: true
});

const getMuiTheme = () =>
    createTheme({
        components: {
            MuiButton: {
                styleOverrides: {
                    root: {
                        fontWeight: "bold",
                    },
                },
            },
            MuiPaper: {
                styleOverrides: {
                    root: {
                        boxShadow: "unset",
                    },
                },
            }
        },
    });


const TabDataTableRenderer = (props) => {
    const { t } = useTranslation();
    const options = {
        selectableRows: "none",
        filterType: "dropdown",
        responsive: "standard",
        tableBodyMaxHeight: "800px",
        onTableChange: (action, state) => {
            console.log(action);
            console.dir(state);
        }
    };
    return (
        <>
            <CCard md={props.element.cardInfo.column} className="custom-card h-100">
                {props.element.cardInfo && props.element.cardInfo.headers && props.element.cardInfo.headers.length > 0 &&
                    <CCardHeader>
                        <CRow>
                            {
                                props.element.cardInfo.headers.map((p, key) => {
                                    return <CCol key={key} md={p.column}><h6 style={p.style?.inlineStyle}>{t(`dynamic.tabdatatable.headers.${p.text}`, p.text.toString())}</h6></CCol>
                                })
                            }
                        </CRow>
                    </CCardHeader>
                }
                <CCardBody>
                    <Tabs id="uncontrolled-tab-example" className="mb-3">

                        {
                            props.element.tabs?.map(item => {
                                var filteredItem = props.element.tabContents.find(p => p.key == item.key);
                                return (
                                    <Tab key={item.key} eventKey={item.key} title={item.value}>
                                        <CacheProvider value={muiCache}>
                                            <ThemeProvider theme={getMuiTheme()}>
                                                <MUIDataTable
                                                    title={""}
                                                    data={filteredItem?.tableValues}
                                                    columns={filteredItem?.tableColumns}
                                                    options={options}
                                                />
                                            </ThemeProvider>
                                        </CacheProvider>
                                    </Tab>
                                )
                            })
                        }
                    </Tabs>
                </CCardBody>
                {props.element.cardInfo && props.element.cardInfo.footers && props.element.cardInfo.footers.length > 0 &&
                    <CCardFooter>
                        <CRow>
                            {
                                props.element.cardInfo.footers.map((p, key) => {
                                    return <CCol key={key} md={p.column}><h6 style={p.style?.inlineStyle}>{t(`dynamic.tabdatatable.footers.${p.text}`, p.text.toString())}</h6></CCol>
                                })
                            }
                        </CRow>
                    </CCardFooter>
                }
            </CCard>
        </>
    );
}

export default TabDataTableRenderer;