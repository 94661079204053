import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Col, Row, Button, FloatingLabel, Form, Container } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { AppContext } from '../../contextapi/AppProvider'
import logo from '../../assets/logo-colored.png';

import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import { toastify } from "../../helpers/common-helper";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from "react-toastify";
import LanguageSelection from "../../components/LanguageSelection";
import { useTranslation } from 'react-i18next';

const Login = (props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { userInfo, setUserInfo } = useContext(AppContext);
    const [userName, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (!userInfo.isUserAuth || (userInfo.isUserAuth && userInfo.isUserAuth !== "true"))
            navigate("/login")
    }, [])

    const handleOnLoginClick = () => {
        if (!userName || !password) {
            toastify.error("Please fill all fields")
            return;
        }
        setLoading(true);
        axios.post(`${window.app.config.USER_API_URL}/authenticate`, { userName, password })
            .then(response => {
                setLoading(false);
                if (response.status == 200) {
                    setUserInfo({...response.data, isUserAuth: true});
                    navigate("/")
                }
            })
            .catch(err => {
                if (err.response)
                    toastify.error(err.response.data.message)
                else
                    toastify.error(err.toString())

                setLoading(false);
            })
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleOnLoginClick();
        }
    }

    return (
        <div>
            <BlockUi tag="div" blocking={loading} message={t("loading")}>
                <div className="c-app-login flex-row">
                    <LanguageSelection isLight/>
                </div>
                <div className="c-app c-app-login flex-row align-items-center">
                    <Container className="h-100">
                        <div className="d-flex justify-content-center h-100">
                            <Col md={4} className="custom-shadow-lg p-3 mb-5 bg-white rounded">
                                <Row className="p-3">
                                    <Col md={8}>
                                        <h4>{t("pages.login.wellcome")}</h4>
                                        <small>{t("pages.login.login_info")}</small>
                                    </Col>
                                    <Col md={4}>
                                        <img src={logo} className="w-100" />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12} className="p-3">
                                        <FloatingLabel
                                            controlId="floatingInput"
                                            label={t("pages.login.placeholders.username")}
                                            className="mb-3"
                                        >
                                            <Form.Control type="text" placeholder={t("pages.login.placeholders.username")} value={userName} onChange={(e) => setUserName(e.target.value)} onKeyDown={handleKeyDown} />
                                        </FloatingLabel>
                                        <FloatingLabel controlId="floatingPassword" label={t("pages.login.placeholders.password")}>
                                            <Form.Control type="password" placeholder={t("pages.login.placeholders.password")} value={password} onChange={(e) => setPassword(e.target.value)} onKeyDown={handleKeyDown} />
                                        </FloatingLabel>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                        <Button className="custom-shadow-lg w-100 p-3 mb-5 rounded btn-login" onClick={handleOnLoginClick}>{t("pages.login.buttons.login")}</Button>
                                    </Col>
                                </Row>
                            </Col>
                        </div>

                        <ToastContainer />
                    </Container>
                </div>
            </BlockUi>
        </div>
    );
}

export default Login;