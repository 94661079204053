import { CCard, CCardBody, CCol, CRow, CCardHeader, CFormSelect, CFormTextarea, CButton } from "@coreui/react";
import Layout from '../Layout';
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { LOCAL_STORAGE_KEYS, PROJECT_DETAILS_LIST_TYPES } from "../../helpers/constants";
import { AppContext } from '../../contextapi/AppProvider';
import { Card } from "react-bootstrap";
import MUIDataTable from 'mui-datatables';
import { toastify } from '../../helpers/common-helper';

const TaskManagement = () => {
    const { setLoading, loading } = useContext(AppContext);
    const [formData, setFormData] = useState([]);
    const { t } = useTranslation();
    const [selectedUser, setSelectedUser] = useState()
    const [usersData, setUsersData] = useState([]);
    const [message, setMessage] = useState()

    const columns = [
        { 
            name: "username",
            label: t(`dynamic.datatable.columns.Username`),
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "deviceId",
            label: t(`dynamic.datatable.columns.DeviceName`),
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "message",
            label: t(`dynamic.datatable.columns.Message`),
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "status",
            label: t(`dynamic.datatable.columns.Status`),
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "insertDateString",
            label: t(`dynamic.datatable.columns.insertDate`),
            options: {
                filter: true,
                sort: true,
            }
        }
    ];

    const options = {
        // filterType: "dropdown",
        filterType: "textField",
        print: false,
        selectToolbarPlacement: 'none',

    };

    const retrieveUsersData = () => {
        setLoading(true)
        axios.get(`${window.app.config.TASKMANAGEMENT.USER_API_URL}`)
            .then((res) => {
                setUsersData(res.data)
                if (res.data.length > 0) {
                    setSelectedUser(res.data[0].value)
                }
                setLoading(false)
            })
            .catch((err) => {
                toastify.error(err.toString())
                setLoading(false)
            })
    }
    const retrieveData = () => {
        setLoading(true);
        axios.post(window.app.config.TASKMANAGEMENT.TASK_ASSIGNMENT_LIST_API_URL)
            .then((res) => {
                setFormData(res.data);
                setLoading(false);
            }) // Handle the response from backend here
            .catch((err) => {
                toastify.error(err.toString());
                setFormData([]);
                setLoading(false);
            }); // Catch errors if any exist
    }

    useEffect(() => {
        retrieveUsersData()
        retrieveData()
    }, [])

    const handleSendMessage = () => {
        var deviceId = selectedUser
        var userMessage = message
        setLoading(true)
        
        axios.post(`${window.app.config.TASKMANAGEMENT.TASK_INSERT_API_URL}`, { deviceId, userMessage })
            .then(response => {
                console.log(response.data)
                toastify.success(t("toastify.Save_Success"))
                setLoading(false);
                retrieveData()
            })
            .catch(err => {
                console.log(err);
                toastify.error(err.toString())
                setLoading(false);
                retrieveData()

            })
    }

    return (
        <Layout>
            <CCol md={12} >
                <CCard className="custom-card bg-white rounded">
                </CCard>
                <CCard className="custom-card bg-white rounded">
                    {/* <CCardHeader >{t("pages.taskmanagement.taskmanagementlist")}</CCardHeader> */}
                    <CCardBody>
                        <CRow md={12}>
                            <MUIDataTable
                                title={t(`dynamic.datatable.headers.task_management`)}
                                data={formData}
                                columns={columns}
                                options={options}
                            />
                        </CRow>
                    </CCardBody>

                </CCard>
            </CCol>
        </Layout>
    )


}
export default TaskManagement;
