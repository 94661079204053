import { CCard, CCardBody, CCardImage, CCardText, CModal, CModalBody, CCol, CPagination, CPaginationItem, CRow } from "@coreui/react";
import { LOCAL_STORAGE_KEYS, PROJECT_DETAILS_LIST_TYPES } from "../../helpers/constants";
import { useContext, useEffect, useState } from "react";
import { AppContext } from '../../contextapi/AppProvider';
import axios from "axios";
import { toastify } from '../../helpers/common-helper';
import Layout from '../Layout';
import { useTranslation } from "react-i18next";
import ModalRenderer from "../../components/ModalRenderer";
import { PaginationControl } from 'react-bootstrap-pagination-control';

const MainPage = () => {
    const { setLoading, loading } = useContext(AppContext);
    const [formData, setFormData] = useState([]);
    const [selectedImagePath, setSelectedImagePath] = useState("");
    const [imgVisible, setImgVisible] = useState(false);
    const [page, setPage] = useState(1);

    const { t } = useTranslation();
 
    useEffect(() => {
        var userInfo = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER_INFO));
        setLoading(true); 
        var startPage = parseInt(page);
        var userId = userInfo.id;
        axios.post(`${window.app.config.STATIC_NAVBAR.VISION_TOOL.PROJECTDETAIL_API_URL}`, { startPage, userId })
            .then(response => {
                console.log(response.data)
                setFormData(response.data)
                setLoading(false);
            })
            .catch(err => {
                console.log(err);
                toastify.error(err.toString())
                setLoading(false);
            })

    }, [page])

    // const signalRHubConnection = async () => {
    //     const connection = new HubConnectionBuilder()
    //         .withUrl(`${window.app.config.SIGNALR_HUB_API_URL}/${window.app.config.SIGNALR_HUB_NAME}`,)
    //         .withAutomaticReconnect()
    //         .build();

    //     connection.on(`${window.app.config.SIGNALR_METHOD_NAME}_${userInfo.id}`, (response) => {

    //         console.log("SignalRResponse", response);
    //         if (response) {

    //             setPage(1)
    //             // set page number
    //         }
    //     })

    //     await connection.start();
    // }

    const onClickImage = (e) => {
        setSelectedImagePath(e.target.src);
        setImgVisible(true);
    }

    const onClickPage = (pageNumber) => {
        if (pageNumber <= 0 || pageNumber > formData?.paggingCount) {
            setPage(1)
        } else {
            setPage(pageNumber)
        }
    }

    return (
        <Layout>
            <CRow>
                {
                    formData?.productImage?.map(item => {
                        return (
                            <CCol md={6} >
                                <CCard className="custom-card p-3 mb-5 bg-white rounded">
                                    <CCardBody>
                                        <CRow>
                                            <CCol md={8} >
                                                <CCardImage onClick={(e) => onClickImage(e)} className="pointer" style={{ borderRadius: 15 }} orientation="top" src={item.imagePath} />
                                            </CCol>
                                            <CCol md={4}>
                                                <CCardBody>
                                                    <CCardText>{item.insertDateString}</CCardText>
                                                    <CCardText>{item.mlResult}</CCardText>
                                                    <CCardText>{item.errorReasonName != "" ? item.errorReasonName : ""}</CCardText>
                                                </CCardBody>
                                            </CCol>
                                        </CRow>
                                    </CCardBody>
                                </CCard>
                            </CCol>
                        )
                    })
                }
                {/* <CPagination align="end">
                    <CPaginationItem style={{ cursor: 'pointer' }} onClick={() => onClickPage((page) - 1)}>{t("pagination.prev")}</CPaginationItem>
                    {
                        Array.from({ length: formData.paggingCount }, (item, index) =>
                            <CPaginationItem style={{ cursor: 'pointer' }} onClick={() => onClickPage(index + 1)}>{index + 1}</CPaginationItem>
                        )
                    }
                    <CPaginationItem style={{ cursor: 'pointer' }} onClick={() => onClickPage((page) + 1)}>{t("pagination.next")}</CPaginationItem>
                </CPagination> */}
                
                <PaginationControl 
                 page={page}
                 between={4}
                 total={formData.paggingCount > 0 ? formData.paggingCount : 1} 
                 limit={10}
                 changePage={(page) => {
                   setPage(page); 
                   console.log(page)
                 }}
                 ellipsis={1}
                  />
            </CRow>
            <ModalRenderer
                visible={imgVisible}
                setVisible={setImgVisible}
                title="Test"
            >
                <img width={"100%"} src={selectedImagePath} />
            </ModalRenderer>
        </Layout>
    )

}
export default MainPage;
