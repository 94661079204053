import React, { useContext } from 'react'
import { CSidebar, CSidebarBrand, CSidebarNav, CSidebarToggler } from '@coreui/react'
import logoTim from '../assets/logo.png'

import SimpleBar from 'simplebar-react'
import 'simplebar/dist/simplebar.min.css'

// sidebar nav config
import { AppContext } from '../contextapi/AppProvider'
import { SidebarNav } from './SidebarNav'
import { useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import IconRenderer from '../components/IconRenderer'
import { CNavGroup, CNavItem, CNavTitle } from "@coreui/react"

const Sidebar = () => {
    const { routerData, sidebarShow, sidebarUnfoldable, setSidebarUnfoldable } = useContext(AppContext);
    const [searchParams, setSearchParams] = useSearchParams();
    const { t } = useTranslation();

    var navData = [
        {
            component: CNavTitle,
            name: t(`navbar.headers.conwo`, "Conwo")
        }
    ]
    var parentData = routerData.filter(item => item.parentId === null);

    parentData.forEach(item => {
        var obj = {
            component: CNavItem,
            name: t(`navbar.labels.${item.text}`, item.text),
            to: `../${item.route}`,
            id: item.id,
            // icon: <IconRenderer iconName={item.icon} /> 
            // <CIcon icon={cilNotes} customClassName="nav-icon" />,
        };
        if (item.icon && item.icon !== "icon")
            obj.icon = <IconRenderer iconName={item.icon} className="pe-3" />
        navData.push(obj)
    })

    var childData = routerData.filter(item => item.parentId !== null);
    childData.forEach(item => {
        var parentData = navData.filter(p => p.id === item.parentId);
        parentData[0].component = CNavGroup;
        parentData[0].items = parentData[0].items ?? [];
        parentData[0].items.push({
            component: CNavItem,
            name: item.text,
            to: `../${item.route}`,
            // icon: <CIcon icon={cilNotes} customClassName="nav-icon" />,
        })
    })

    navData.push(
        {
            component: CNavTitle,
            name: t(`navbar.headers.tools`, "Tools"),
        },
        {
            component: CNavItem,
            name: t(`navbar.labels.visiontool`, "Vision Tool"),
            to: `../visiontool`,
            id: "vision_tool",
            icon: <IconRenderer iconName={"Highlight"} className="pe-3" />
        },
        {
            component: CNavItem,
            name: t(`navbar.labels.pickingtool`, "Picking Tool"),
            to: `../pickingtool`,
            id: "picking_tool",
            icon: <IconRenderer iconName={"Widgets"} className="pe-3" />
        },
        {
            component: CNavItem,
            name: t(`navbar.labels.annotationtool`, "Annotation Tool"),
            to: `../annotationproject`,
            id: "annotation_project",
            icon: <IconRenderer iconName={"Widgets"} className="pe-3" />
        },
        {
            component: CNavGroup,
            name: t(`navbar.labels.taskmanagement`, "Task Management"),
            icon: <IconRenderer iconName={"Widgets"} className="pe-3"/>,
            items: [
    
                // {
                //     component: CNavItem,
                //     name: t(`navbar.labels.taskmanagement`, "Overview"),
                //     to: '../taskmanagement',
                // },
                {
                    component: CNavItem,
                    name: t(`navbar.labels.adminpanel`, "Admin Panel"),
                    to: '../taskmanagementadminpanel',
                },
                {
                    component: CNavItem,
                    name: t(`navbar.labels.taskassignment`, "Task Assignment"),
                    to: '../taskassignment',
                },
            ],
        },



    )
    return (
        <CSidebar
            position="fixed"
            unfoldable={sidebarUnfoldable}
            visible={sidebarShow}>
            <CSidebarBrand className="d-none d-md-flex ps-3 pt-4" to="/">
                <img src={logoTim} height={90} alt="" />
            </CSidebarBrand>
            <CSidebarNav>
                <SimpleBar className='ps-4 pt-4 pb-4'>
                    <SidebarNav items={navData} />
                </SimpleBar>
            </CSidebarNav>
            {/* <CSidebarToggler
                className="d-none d-lg-flex"
                onClick={() => setSidebarUnfoldable(!sidebarUnfoldable)}
            /> */}
        </CSidebar>
    )
}

export default React.memo(Sidebar)
