import React from 'react';
import { useContext } from 'react';

import { Navigate, Outlet } from 'react-router-dom'
import { AppContext } from '../../contextapi/AppProvider';

const useAuth = () => {
    const { userInfo } = useContext(AppContext)
    if (!userInfo.isUserAuth || (userInfo.isUserAuth && userInfo.isUserAuth === "false")) {
        return false;
    } else {
        return true;
    }
}

const PublicRoutes = (props) => {
    const auth = useAuth()

    return auth ? <Navigate to="/" /> : <Outlet />
}

export default PublicRoutes;