import { CCard, CCardBody, CCardFooter, CCardHeader, CCol, CRow } from '@coreui/react';
import React, { useState, useContext, useEffect } from 'react';
import Chart from "react-apexcharts";
import { AppContext } from '../contextapi/AppProvider';
import { useTranslation } from 'react-i18next';
import { generateRequestFilterObjectFromQueryParamsAndDropdownData } from '../helpers/filter-helper';
import { ServiceHelper } from '../helpers/service-helper';
import { useSearchParams } from 'react-router-dom';


const HorizontalBarChartRenderer = (props) => {
    const { dropDownFilterData } = useContext(AppContext);
    const { t } = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams();

    const [chartOptions, setChartOptions] = useState({
        options: {
            chart: {
                type: 'bar',
                height: 350,
                stacked: true,
                stackType: '100%'
            },
        },
        series: []
    })

    useEffect(() => {
        let obj = { ...chartOptions };
        obj.options = {
            plotOptions: {
                bar: {
                    horizontal: true,
                },
            },
            colors: [],
            stroke: {
                width: 1,
                colors: ['#fff']
            },
            title: {
                text: ''
            },
            xaxis: {
                categories: [],
            },
            tooltip: {
                y: {
                    formatter: function (val) {
                        return val
                    }
                }
            },
            fill: {
                opacity: 1

            },
            legend: {
                position: 'top',
                horizontalAlign: 'left',
                offsetX: 40
            }
        }

        if (props.element.url) {
            props.setLoading(true);
            var filters = generateRequestFilterObjectFromQueryParamsAndDropdownData(searchParams, dropDownFilterData)
            var _serviceHelper = new ServiceHelper(props.element.url, filters);
            _serviceHelper._post(
                (response) => {
                    obj.series = response.data.series;
                    obj.options.xaxis.categories = response.data.labels;
                    obj.options.colors = response.data.colors;
                    setChartOptions(obj);
                    props.setLoading(false);
                },
                (err) => {
                    console.log(err);

                    obj.series = [];
                    obj.options.xaxis.categories = [];
                    obj.options.colors = [];
                    setChartOptions(obj)
                    props.setLoading(false);
                })
        }
        else {
            obj.series = props.element.series;
            obj.options.xaxis.categories = props.element.labels;
            obj.options.colors = ["#48484a", "#ed7221"]//props.element.colors;
            setChartOptions(obj)        
        }
    }, [])

    return (
        <>
            <CCard className="custom-card h-100">
                {props.element.cardInfo && props.element.cardInfo.headers && props.element.cardInfo.headers.length > 0 &&
                    <CCardHeader>
                        <CRow>
                            {
                                props.element.cardInfo.headers.map((p, key) => {
                                    return <CCol key={key} md={p.column}><h6 style={p.style?.inlineStyle}>{t(`dynamic.graphics.headers.${p.text}`, p.text.toString())}</h6></CCol>
                                })
                            }
                        </CRow>
                    </CCardHeader>
                }
                <CCardBody>
                    <Chart
                        options={chartOptions.options}
                        series={chartOptions.series}
                        type="bar"
                        height={350}
                    />
                </CCardBody>
                {props.element.cardInfo && props.element.cardInfo.footers && props.element.cardInfo.footers.length > 0 &&
                    <CCardFooter>
                        <CRow>
                            {
                                props.element.cardInfo.footers.map((p, key) => {
                                    return <CCol key={key} md={p.column}><h6 style={p.style?.inlineStyle}>{t(`dynamic.graphics.footers.${p.text}`, p.text.toString())}</h6></CCol>
                                })
                            }
                        </CRow>
                    </CCardFooter>
                }
            </CCard>
        </>
    );
}

export default HorizontalBarChartRenderer;