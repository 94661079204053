import React, { useState, useEffect, useContext } from 'react';
import { LANGUAGES } from '../helpers/constants';
import {
    cilGlobeAlt,
} from '@coreui/icons'
import i18next from 'i18next';
import CIcon from '@coreui/icons-react';
import { Dropdown, DropdownButton, FormControl, InputGroup } from 'react-bootstrap';
import classNames from 'classnames';
import cookies from 'js-cookie';
import { AppContext } from '../contextapi/AppProvider';

import 'flag-icon-css/css/flag-icons.min.css';


const LanguageSelection = (props) => {
    const { currentLanguageCode, setCurrentLanguageCode } = useContext(AppContext);
    const onClick = (code) => {
        
        setCurrentLanguageCode(code);
        i18next.changeLanguage(code)
    }
    useEffect(() => {
        console.log('Setting page stuff')
        const languageCode = cookies.get('i18next') || 'en'
        setCurrentLanguageCode(languageCode)
    }, [])

    return (
        <div className='d-flex justify-content-end'>
            <DropdownButton
                variant={classNames("outline-link", {"text-light": props.isLight})}
                title={<CIcon width={25} icon={cilGlobeAlt} />}
                id="langDrp"
                style={{paddingTop: 0}}
            >
                {
                LANGUAGES.map(({ code, name, country_code }) => (
                    <Dropdown.Item key={country_code}>
                        <button className='dropdown-item'
                            onClick={() => onClick(code)}
                        >
                            <span
                                className={`flag-icon flag-icon-${country_code} mx-2`}
                            ></span>
                            {name}
                        </button>
                    </Dropdown.Item>
                ))}
            </DropdownButton>
        </div>
    );
}

export default LanguageSelection;