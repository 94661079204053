import React from 'react'
import Layout from '../../../Layout';
import MUIDataTable from "mui-datatables";
import axios from 'axios';
import { useState, useEffect } from "react";
import { Button, Modal, Form } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import { toastify } from '../../../../helpers/common-helper';
import { useContext } from 'react';
import { AppContext } from '../../../../contextapi/AppProvider';
import { useTranslation } from "react-i18next";

const OvertimeDistribution = (props) => {
    const { t } = useTranslation();
    const { userInfo } = useContext(AppContext);
    const [tableData, setTableData] = useState([]);
    const [breakTimes, setBreakTimes] = useState([]);
    const [selectedBreakTime, setSelectedBreakTime] = useState(null);

    const setStart = (e) => {
        selectedBreakTime.startTime = e.target.value + ":00";
        setSelectedBreakTime(selectedBreakTime);
    }
    const setEnd = (e) => {
        selectedBreakTime.endTime = e.target.value + ":00";
        setSelectedBreakTime(selectedBreakTime);
    } 
    const setDuration = (e) => {
        selectedBreakTime.amount = e.target.value;
        setSelectedBreakTime(selectedBreakTime);
    }

    const removeButtonClick = (breakTimeId) => {
        axios.get(window.app.config.BREAKTIME.DELETE_API_URL + '?breakTimeId=' + breakTimeId.value, {
            headers: { "Authorization": userInfo.token }
        }).then((response) => {
            var index = breakTimes.findIndex(s=>s.id == breakTimeId.value);
            breakTimes.splice(index,1);
            setBreakTimes(breakTimes);
            setTableData(prepareTableData(breakTimes));
            toastify.success("Silme işlemi gerçekleşti")

        }).catch((err) => {
            console.log(err);
            toastify.error("Hata oluştu")

        })
    } 
    const prepareTableData = (breakTimes) => {
        var rows = [];
        breakTimes.forEach(function (item) {
            var columns = [];
            columns.push(item.startTime);
            columns.push(item.endTime);
            columns.push(item.amount);
            columns.push(item.id);
            rows.push(columns);
        })
        return rows;
    } 
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);

    const handleShow = (breakTimeId) => {
        if (breakTimeId == 0) {
            setSelectedBreakTime({
                id: 0
            })
            setShow(true);
            return;
        }
        setSelectedBreakTime(breakTimes.filter(s => s.id == breakTimeId)[0]);
        setShow(true);
    }

    const submitBreakTimeForm = () => {
        var postUrl = selectedBreakTime.id == 0 ?
            window.app.config.BREAKTIME.CREATE_API_URL :
            window.app.config.BREAKTIME.UPDATE_API_URL;
 
            
        axios.post(postUrl, {
            startTime: selectedBreakTime.startTime ,
            id: selectedBreakTime.id,
            endTime: selectedBreakTime.endTime ,
            amount: selectedBreakTime.amount,
            branchId : props.branchId
        }, {
            headers: { "Authorization": userInfo.token }
        }).then((response) => {
            var data = response.data;
            var index = breakTimes.findIndex(s => s.id == data.id);
            if (index == -1) {
                breakTimes.push(data);
            } else {
                breakTimes[index] = data;
            }
            setBreakTimes(breakTimes);
            setTableData(prepareTableData(breakTimes));
            toastify.success("İşleminiz başarıyla kaydedildi")
        }).catch((err) => {
            console.log("error", err);
            toastify.error("Hata oluştu")

        })
    } 
    useEffect(() => {
        axios.get(window.app.config.BREAKTIME.LIST_API_URL+ '?branchId=' + props.branchId ?? 1, {
            headers: { "Authorization": userInfo.token }
        }).then((response) => {
            var data = response.data;
            setBreakTimes(data);
            setTableData(prepareTableData(data));
        }).catch((response) => {
            console.log("error", response);
        });
    }, [props])


    const columns = [
        {
            name: "StartTime",
            label: t("pages.overtimeDistribution.start")
        }, {
            name: "EndTime",
            label: t("pages.overtimeDistribution.end")
        }, {
            name: "Amount",
            label: t("pages.overtimeDistribution.amount")
        },   {
            name: "Actions",
            label: t("pages.overtimeDistribution.operations"),
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <div>
                            <Button  variant='btn btn-info btn-round' onClick={() => { handleShow(value); }}> <Icon.Pencil /> </Button>
                            <Button variant="btn btn-danger btn-round" onClick={() => { removeButtonClick({ value }); }}> <Icon.Trash /> </Button>
                        </div>
                    )
                }
            }
        }]

        const options = {
            filterType: 'textField',
        // selectableRows:false

        
        };

        return (
            <>
                <Button className='btn btn-info btn-round' onClick={() => { handleShow(0); }}> { t("pages.overtimeDistribution.add") } </Button>
                <hr />
                <MUIDataTable
                    title={t("pages.overtimeDistribution.overtimeDistribution")}
                    data={tableData}
                    columns={columns}
                    options={options}
                />
                <Modal show={show} onHide={handleClose} animation={false}>
                    <Modal.Header closeButton>
                        <Modal.Title> {t("pages.overtimeDistribution.amount")} </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group className="mb-3" controlId="startTime">
                                <Form.Label>{t("pages.overtimeDistribution.start")}</Form.Label>
                                <Form.Control type="time" defaultValue={selectedBreakTime?.startTime} onChange={setStart} />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="endTime">
                                <Form.Label>{t("pages.overtimeDistribution.end")}</Form.Label>
                                <Form.Control type="time" defaultValue={selectedBreakTime?.endTime} onChange={setEnd} />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="duration">
                                <Form.Label>{t("pages.overtimeDistribution.amount")}</Form.Label>
                                <Form.Control type="text" defaultValue={selectedBreakTime?.amount} onChange={setDuration} />
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                        {t("pages.overtimeDistribution.close")}
                        </Button>
                        <Button variant="primary" onClick={() => { submitBreakTimeForm(); handleClose(); }}>
                          {t("pages.overtimeDistribution.save")}
                        </Button>
                    </Modal.Footer>
                </Modal>
                </>
        )
}

export default React.memo(OvertimeDistribution)
