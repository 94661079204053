import { toast } from 'react-toastify';

export const toastify = {
    theme: "colored",
    success: function(message){
        toast.success(message, {theme: this.theme})
    },
    error: function(message){
        toast.error(message, {theme: this.theme})
    },
    warning: function(message){
        toast.warning(message, {theme: this.theme})
    }
}


export const generateNavDataFromRouterData = (routerData, searchParams, translation) => {

    // return navData;
}

export const checkAndGetTranslations = (i18n, translations, key) => {
    
    if(i18n.exists(key))
        return translations(key)
    else return key;
}

export const checkObjectTypeForArray = (object) => {
    if(Object.prototype.toString.call(object) === '[object Array]') {
        return object
    }

    return [];
}

export const pullSettingsFromLocalStorage = function pullSettingsFromLocalStorage() {
    if (!window || !window.localStorage) return {};
    var settings = {};

    for (var i = 0; i < window.localStorage.length; i++) {
        var key = window.localStorage.key(i);

        if (key.startsWith("settings_")) {
            try {
                settings[key.replace("settings_", "")] = JSON.parse(window.localStorage.getItem(key));
            } catch (e) { }
        }
    }
    
    return settings;
};

export const isEmpty = (obj) => {
    return Object.keys(obj).length === 0;
}

export const getKeyByValue = (object, value) => {
    return Object.keys(object).find(key => object[key] === value);
  }