import React from 'react';
import { CModal, CModalBody } from "@coreui/react";

const ModalRenderer = (props) => {
    return (
        <CModal size="lg" alignment="center" visible={props.visible} onClose={() => props.setVisible(false)}>
            <CModalBody style={{padding: 0}}>
                {props.children}
            </CModalBody>
        </CModal>
    );
}

export default ModalRenderer;