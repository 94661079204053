import { CCard, CCol, CRow, CCardHeader, CCardFooter, CCardBody } from "@coreui/react";
import MUIDataTable from "mui-datatables";
import { ThemeProvider } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { useTranslation } from "react-i18next";
import { useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { AppContext } from "../contextapi/AppProvider";
import { ServiceHelper } from "../helpers/service-helper";
import { generateRequestFilterObjectFromQueryParamsAndDropdownData } from "../helpers/filter-helper";

const muiCache = createCache({
    key: "mui-datatables",
    prepend: true
});

const getMuiTheme = () =>
    createTheme({
        components: {
            MuiButton: {
                styleOverrides: {
                    root: {
                        fontWeight: "bold",
                    },
                },
            },
        },
    });


const DataTableRenderer = (props) => {
    const { t } = useTranslation();
    const { dropDownFilterData } = useContext(AppContext);
    const [searchParams, setSearchParams] = useSearchParams();
    const [tableData, setTableData] = useState([]);
    const [tableColumns, setTableColumns] = useState([]);
    const [pageCount, setPageCount] = useState(1);

    const sendRequestApi = (state = null) => {
        if (props.element.url) {
            props.setLoading(true);
            var filters = generateRequestFilterObjectFromQueryParamsAndDropdownData(searchParams, dropDownFilterData)
            if (state != null) {
                var page = state.page;
                filters[0].page = page;
            }

            var _serviceHelper = new ServiceHelper(props.element.url, filters);
            _serviceHelper._post(
                (response) => {
                    var data = { ...tableData }
                    console.log(data);
                    setPageCount(response.data.pageCount)
                    // data.columns = [];
                    // // data.values = [];
                    // response.data.tableColumns.forEach(function(col){
                    //     data.columns.push(t(`dynamic.datatable.columns.${col}`));
                    // })
                    // // data.columns = response.data.tableColumns;
                    // data.values = response.data.tableValues;
                    console.log(data);
                    setTableData(response.data.tableValues);
                    setTableColumns(response.data.tableColumns);
                    props.setLoading(false);
                },
                (err) => {
                    setTableData([]);
                    setTableColumns([]);
                    console.log(err);
                    props.setLoading(false);
                })
        }
        else {
            setTableData(props.element.tableValues);
            setTableColumns(props.element.tableColumns);
        }
    }

    const options = {
        selectableRows: "none",
        filterType: "dropdown",
        rowsPerPageOptions: [],
        search: false,
        filter: false,
        responsive: "standard",
        tableBodyMaxHeight: "800px",
        count: pageCount,
        serverSide: true,
        onTableChange: (action, state) => {
            if (action == "changePage") {
                sendRequestApi(state)
            }
            console.log(action);
            console.dir(state);
        }
    };

    useEffect(() => {
        sendRequestApi()
    }, []);


    return (
        <>
            {
                props.element.cardInfo ?
                    <CCard md={props.element.cardInfo.column} className="custom-card h-100">
                        {props.element.cardInfo && props.element.cardInfo.headers && props.element.cardInfo.headers.length > 0 &&
                            <CCardHeader>
                                <CRow>
                                    {
                                        props.element.cardInfo.headers.map((p, key) => {
                                            return <CCol key={key} md={p.column}><h6 style={p.style?.inlineStyle}>{t(`dynamic.datatable.headers.${p.text}`, p.text.toString())}</h6></CCol>
                                        })
                                    }
                                </CRow>
                            </CCardHeader>
                        }
                        <CCardBody>
                            <CacheProvider value={muiCache}>
                                <ThemeProvider theme={getMuiTheme()}>
                                    <MUIDataTable
                                        title={t(`dynamic.datatable.headers.${props.element.header}`, props.element.header.toString())}
                                        data={tableData}
                                        columns={tableColumns.map(item => {
                                            return t(`dynamic.datatable.columns.${item}`);
                                        })}
                                        options={options}
                                    />
                                </ThemeProvider>
                            </CacheProvider>
                        </CCardBody>
                        {props.element.cardInfo && props.element.cardInfo.footers && props.element.cardInfo.footers.length > 0 &&
                            <CCardFooter>
                                <CRow>
                                    {
                                        props.element.cardInfo.footers.map((p, key) => {
                                            return <CCol key={key} md={p.column}><h6 style={p.style?.inlineStyle}>{t(`dynamic.datatable.footers.${p.text}`, p.text.toString())}</h6></CCol>
                                        })
                                    }
                                </CRow>
                            </CCardFooter>
                        }
                    </CCard>
                    :
                    <div style={{ marginBottom: "10px" }} className="h-100">
                        <CacheProvider value={muiCache}>
                            <ThemeProvider theme={getMuiTheme()}>
                                <MUIDataTable
                                    title={props.element.header}
                                    data={props.element.tableValues}
                                    columns={props.element.tableColumns}
                                    options={options}
                                />
                            </ThemeProvider>
                        </CacheProvider>
                    </div>
            }
        </>
    );
}

export default DataTableRenderer;