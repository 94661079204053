import { CCard, CCardBody, CCardFooter, CCardHeader, CCol, CNavItem, CRow } from '@coreui/react';
import React, { useState } from 'react';
import Chart from "react-apexcharts";
import { useTranslation } from 'react-i18next';


const AreaChartRenderer = (props) => {
    console.log("Chart Props", props);
    const { t } = useTranslation();
    const [chartOptions, setChartOptions] = useState({
        options: {
            chart: {
                id: "chart",
                group: props.element.groupKey,
                type: 'area',
                sparkline: {
                    enabled: true
                },
            },
            stroke: {
                curve: 'straight'
            },
            fill: {
                opacity: 1,
            },
            labels: props.element.series.map(function (d) { return d.key }),
            yaxis: {
                min: 0,
            },
            xaxis: {
                type: 'category',
            },
            colors: ['#f36533'],
            title: {
                text: props.element.subValue,
                offsetX: 30,
                style: {
                    fontSize: '24px',
                    cssClass: 'apexcharts-yaxis-title'
                }
            },
            subtitle: {
                text: props.element.subHeader,
                offsetX: 30,
                style: {
                    fontSize: '14px',
                    cssClass: 'apexcharts-yaxis-title'
                }
            }
        },
        series: [
            {
                name: props.element.subHeader,
                data: props.element.series.map(p => p.value)
            }
        ]
    })

    return (
        <>
            <CCard className="custom-card h-100">
                {props.element.cardInfo && props.element.cardInfo.headers && props.element.cardInfo.headers.length > 0 &&
                    <CCardHeader>
                        <CRow>
                            {
                                props.element.cardInfo.headers.map((p, key) => {
                                    return <CCol key={key} md={p.column}><h6 style={p.style?.inlineStyle}>{t(`dynamic.graphics.headers.${p.text}`, p.text.toString())}</h6></CCol>
                                })
                            }
                        </CRow>
                    </CCardHeader>
                }
                <CCardBody>
                    <Chart
                        options={chartOptions.options}
                        series={chartOptions.series}
                        type={props.element.type}
                        height={200}
                    />
                </CCardBody>
                {props.element.cardInfo && props.element.cardInfo.footers && props.element.cardInfo.footers.length > 0 &&
                    <CCardFooter>
                        <CRow>
                            {
                                props.element.cardInfo.footers.map((p, key) => {
                                    return <CCol key={key} md={p.column}><h6 style={p.style?.inlineStyle}>{t(`dynamic.graphics.footers.${p.text}`, p.text.toString())}</h6></CCol>
                                })
                            }
                        </CRow>
                    </CCardFooter>
                }
            </CCard>
        </>);
}

export default AreaChartRenderer;