import { CButton, CRow } from '@coreui/react';
import React, { useState, useEffect, useContext, useRef } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import DropdownRenderer from '../../components/DropdownRenderer';
import { AppContext } from '../../contextapi/AppProvider';
import { ELEMENT_TYPES } from '../../helpers/constants';
import { generateRequestFilterObjectFromQueryParams } from '../../helpers/filter-helper';
import { ServiceHelper } from '../../helpers/service-helper';
import Layout from '../Layout';
import jsPDF from "jspdf";
import html2canvas from 'html2canvas';
import {
    cilSave
  } from '@coreui/icons'
import CIcon from '@coreui/icons-react';
import BaseRenderer from '../../components/BaseRenderer';
import { useTranslation } from 'react-i18next';

const Report = (props) => {
    const { setLoading, routerData, dropDownFilterData, userInfo } = useContext(AppContext);
    const [reportFormData, setReportFormData] = useState([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const { t } = useTranslation();
    const elementRef = useRef(null);

    const savePDF = () => {
        const element = elementRef.current;
        html2canvas(element, {
            scale: 0.8,
            logging: true,
            allowTaint: false,
        }).then(function(canvas) {
            // Export the canvas to its data URI representation
            const imgWitdh = 208;
            const imgHeigth = canvas.height * imgWitdh / canvas.width;
            const imgData = canvas.toDataURL('img/png');

            const pdf = new jsPDF('p', 'mm', 'a4');
            pdf.addImage(imgData, 'PNG', 1, 5, imgWitdh, imgHeigth);
            pdf.save('generatedPDF.pdf');
        });
    };

    const savePDFStatic = () => {
        fetch('(NEW) CONWO Dashboard.pdf').then(response => {
            response.blob().then(blob => {
                // Creating new object of PDF file
                const fileURL = window.URL.createObjectURL(blob);
                // Setting various property values
                let alink = document.createElement('a');
                alink.href = fileURL;
                alink.download = '(NEW) CONWO Dashboard.pdf.pdf';
                alink.click();
            })
        })
    };

    useEffect(() => {
        setLoading(true);
        var filters = generateRequestFilterObjectFromQueryParams(searchParams)
        var routerId = routerData.find(item => item.route === "/report");
        if (routerId) {
            var _serviceHelper = new ServiceHelper(`${window.app.config.BASE_API_URL}/dashboardcontent`,
                {
                    dashboardpageid: routerId?.id ?? 1,
                    filters
                },
                {
                    headers: { 'Authorization': userInfo.token }
                });
            _serviceHelper._post(
                (response) => {
                    setReportFormData([...response.data])
                    setLoading(false);
                },
                (err) => {
                    setReportFormData([])
                    console.log(err);
                    setLoading(false);
                }
            )
        }
    }, [dropDownFilterData, routerData])

    return (
        <Layout {...props}>
            <div className="d-grid pb-3 gap-2 d-md-flex justify-content-md-end">
                <CButton color="warning" onClick={savePDFStatic}><CIcon icon={cilSave} className="me-2" />{t('pages.report.exportpdf')}</CButton>
            </div>
            {
                reportFormData.filter(item => item.type === ELEMENT_TYPES.DROPDOWN).map((item, index) => {
                    return <DropdownRenderer  key={index} {...props} element={item} />
                })
            }
            <CRow ref={elementRef}>
                {
                    reportFormData?.filter(item => item.type !== ELEMENT_TYPES.DROPDOWN).map((item, index) => {
                        return <BaseRenderer renderItem={item} key={index} {...props} />
                    })
                }
            </CRow>

        </Layout>
    );
}

export default Report;