import React, { useContext, useState } from 'react';
import { useRef } from 'react';
import { CanvasFunctions, RectFunctions } from '../helpers/canvas-helper';
import { Button, ButtonGroup, Card, Col, Form, Row, ToggleButton } from 'react-bootstrap';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { AppContext } from '../contextapi/AppProvider';
import { ServiceHelper } from '../helpers/service-helper';
import { generatedRequestParams, generateRequestFilterObjectFromQueryParamsAndDropdownData } from '../helpers/filter-helper';
import { CCard, CCardBody, CCardHeader } from '@coreui/react';
import { HEAT_MAP_FILTERS, MAP_TYPE } from '../helpers/constants';
import { useTranslation } from 'react-i18next';

let rectObject = [];
let offsetX, offsetY;

const HeatMapRenderer = (props) => {
    const { dropDownFilterData } = useContext(AppContext);
    const { t } = useTranslation();

    const [searchParams, setSearchParams] = useSearchParams();
    const [mapData, setMapData] = useState({});
    const [filterType, setFilterType] = useState(HEAT_MAP_FILTERS.DAILY);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const canvasRef = useRef(null);
    const mapImg = new Image();
    mapImg.src = mapData?.branchMaps?.find(p => p.type === MAP_TYPE.HEAT)?.imgPath;

    useEffect(() => {
        reOffset();
        window.onscroll = function (e) { reOffset(); }
        window.onresize = function (e) { reOffset(); }
    }, [])

    const reOffset = () => {
        var BB = canvasRef.current.getBoundingClientRect();
        offsetX = BB.left;
        offsetY = BB.top;
    }

    useEffect(() => {
        if (props.element.url && filterType !== HEAT_MAP_FILTERS.CUSTOM) {
            props.setLoading(true);
            var filters = generateRequestFilterObjectFromQueryParamsAndDropdownData(searchParams, dropDownFilterData)
            filters.push(generatedRequestParams('dateFilterType', filterType))

            var _serviceHelper = new ServiceHelper(props.element.url, filters);
            _serviceHelper._post(
                (response) => {
                    props.setLoading(false);
                    setMapData(response.data);

                },
                (err) => {
                    props.setLoading(false);
                    setMapData({});
                    console.log(err);

                })
        }
    }, [filterType])

    const onClickSearch = () => {
        if (props.element.url) {
            if(filterType === HEAT_MAP_FILTERS.CUSTOM) {
                if(!startDate || !endDate)
                    return;
            }
            props.setLoading(true);

            var filters = generateRequestFilterObjectFromQueryParamsAndDropdownData(searchParams, dropDownFilterData)
            filters.push(generatedRequestParams('startDate', startDate))
            filters.push(generatedRequestParams('endDate', endDate))
            filters.push(generatedRequestParams('dateFilterType', filterType))

            var _serviceHelper = new ServiceHelper(props.element.url, filters);
            _serviceHelper._post(
                (response) => {
                    props.setLoading(false);
                    setMapData(response.data);

                },
                (err) => {
                    props.setLoading(false);
                    setMapData({});
                    console.log(err);
                })
        }
    }

    useEffect(() => {
        if (mapData && mapData.branchMaps) {
            console.log("mapData.locations", mapData.locations)
            rectObject = [];
            mapImg.onload = function () {
                CanvasFunctions.DrawCanvasImage(canvasRef.current, this);
                mapData.locations.forEach(item => {
                    rectObject.push(new RectFunctions.RectForHeat(item.address, item.xPoint, item.yPoint, item.markerWidth, item.markerHeight, `${item.heatAddress}: ${item.count}`, item.color, canvasRef.current));
                })
            };
        }
    }, [mapData])

    const handleMouseMoveCanvas = (e) => {
        reOffset();
        CanvasFunctions.DrawCanvasImage(canvasRef.current, mapImg);
        let mouseX = parseInt(e.clientX - offsetX);
        let mouseY = parseInt(e.clientY - offsetY);
        // Put your mousemove stuff here
        
        var selectedObject = null;
        for (var i = 0; i < rectObject.length; i++) {
            if (rectObject[i].isPointInside(mouseX, mouseY)) {
                selectedObject = rectObject[i];
            } else {
                rectObject[i].redraw();
            }
        }
        if (selectedObject)
            selectedObject.highlight();

    }

    return (
        <>
            <Row>
                <Col md={12} style={{ textAlign: "center" }}>
                    <CCard className='custom-card'>
                        <CCardHeader>
                            <ButtonGroup>
                                <ToggleButton id="monthly" type='radio' variant='outline-warning'
                                    value={HEAT_MAP_FILTERS.MONTHLY}
                                    checked={filterType === HEAT_MAP_FILTERS.MONTHLY}
                                    onChange={(e) => setFilterType(e.currentTarget.value)}>
                                    {t(`dynamic.heatmap.labels.monthly`, "MONTHLY")}
                                </ToggleButton>
                                <ToggleButton id="weekly" type='radio' variant='outline-warning'
                                    value={HEAT_MAP_FILTERS.WEEKLY}
                                    checked={filterType === HEAT_MAP_FILTERS.WEEKLY}
                                    onChange={(e) => setFilterType(e.currentTarget.value)}>
                                    {t(`dynamic.heatmap.labels.weekly`, "WEEKLY")}
                                </ToggleButton>
                                <ToggleButton id="daily" type='radio' variant='outline-warning'
                                    value={HEAT_MAP_FILTERS.DAILY}
                                    checked={filterType === HEAT_MAP_FILTERS.DAILY}
                                    onChange={(e) => setFilterType(e.currentTarget.value)}>
                                    {t(`dynamic.heatmap.labels.daily`, "DAILY")}
                                </ToggleButton>
                                <ToggleButton id="custom" type='radio' variant='outline-warning'
                                    value={HEAT_MAP_FILTERS.CUSTOM}
                                    checked={filterType === HEAT_MAP_FILTERS.CUSTOM}
                                    onChange={(e) => setFilterType(e.currentTarget.value)}>
                                    {t(`dynamic.heatmap.labels.custom`, "CUSTOM")}
                                </ToggleButton>
                            </ButtonGroup>
                            {
                                filterType === HEAT_MAP_FILTERS.CUSTOM &&
                                <Row className='mt-2'>
                                    <Col md={5}>
                                        <Form>
                                            <Form.Group style={{textAlign:"left"}} controlId="exampleForm.ControlInput1">
                                                <Form.Label>{t(`dynamic.heatmap.labels.startDate`, "Start Date")}</Form.Label>
                                                <Form.Control
                                                    type="date"
                                                    name="datepic"
                                                    placeholder="DateRange"
                                                    value={startDate}
                                                    onChange={(e) => setStartDate(e.target.value)}
                                                />
                                            </Form.Group>
                                        </Form>
                                    </Col>
                                    <Col md={5}>
                                        <Form>
                                            <Form.Group style={{textAlign:"left"}} controlId="exampleForm.ControlInput2">
                                                <Form.Label>{t(`dynamic.heatmap.labels.endDate`, "End Date")}</Form.Label>
                                                <Form.Control
                                                    type="date"
                                                    name="datepic"
                                                    placeholder="DateRange"
                                                    value={endDate}
                                                    onChange={(e) => setEndDate(e.target.value)}
                                                />
                                            </Form.Group>
                                        </Form>
                                    </Col>
                                    <Col md={2} className='align-self-end'>
                                        <Button variant="success" onClick={onClickSearch}>{t(`dynamic.heatmap.labels.search`, "Search")}</Button>
                                    </Col>
                                </Row>
                            }
                        </CCardHeader>
                        <CCardBody>
                            <Col md={12}>
                                <canvas ref={canvasRef} width="1000" height="1000" onMouseMove={handleMouseMoveCanvas}></canvas>
                            </Col>
                        </CCardBody>
                    </CCard>
                </Col>
            </Row>
        </>
    );
}

export default HeatMapRenderer;