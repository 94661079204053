import React from 'react'
import { CFooter } from '@coreui/react'
import classNames from 'classnames';
import { useContext } from 'react';
import { AppContext } from '../contextapi/AppProvider';

const Footer = () => {
  const { sidebarShow } = useContext(AppContext);
  const circleClass = classNames("base-circle", {"hide-item" : !sidebarShow})
  return (
    <div>
      <div className={circleClass}></div>
      <CFooter fixed="false">
        <div>
          <span className="ml-1">&copy; Copyright 2022 Thread In Motion All Rights Reserved</span>
        </div>
        <div className="mfs-auto">
          {/* <span className="mr-1">TIM Seat Quality System</span> */}
        </div>
      </CFooter>
    </div>
  )
}

export default React.memo(Footer)
