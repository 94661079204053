import React from 'react'
import Layout from '../../../Layout';
import MUIDataTable from "mui-datatables";
import axios from 'axios';
import { useState, useEffect } from "react";
import { Button, Modal, Form } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import { toastify } from '../../../../helpers/common-helper';
import { useContext } from 'react';
import { AppContext } from '../../../../contextapi/AppProvider';
import { useTranslation } from 'react-i18next';


const Linesettings = (props) => {
    const { userInfo } = useContext(AppContext);
    const [tableData, setTableData] = useState([]);
    const [lines, setlines] = useState([]);
    const [selectedLine, setSelectedLine] = useState(null);
    const { t } = useTranslation();

    const setLineName = (e) => {
        selectedLine.name = e.target.value;
        setSelectedLine({...selectedLine});
    }
 
    const setLetter = (e) => {
        selectedLine.letter = e.target.value;
        setSelectedLine({...selectedLine});
    }

    const setStart = (e) => {
        selectedLine.startIndex = e.target.value;
        setSelectedLine({...selectedLine});
    }
    const setEnd = (e) => {
        selectedLine.lastIndex = e.target.value;
        setSelectedLine({...selectedLine});
    }

    const removeButtonClick = (lineId) => {
        
        axios.get(window.app.config.LINEDETAIL.LINE_DELETE_API_URL + '?lineId=' + lineId.value, {
            headers: { "Authorization": userInfo.token }
        }).then((response) => {
            var index = lines.findIndex(s=>s.id == lineId.value);
            lines.splice(index,1);
            setlines(lines);
            setTableData(prepareTableData(lines));
            toastify.success(t("toastify.Delete_Success"))
            
        }).catch((err) => {
            console.log(err);
            toastify.error(t("toastify.Delete_Error"))

        })
    }
    const prepareTableData = (lines) => {
        var rows = [];
        lines.forEach(function (item) {
            var columns = [];
            columns.push(item.name);
            columns.push(item.letter);
            columns.push(item.startIndex);
            columns.push(item.lastIndex); 
            columns.push(item.id);
            rows.push(columns);
        })
        return rows;
    }

    const [show, setModalShow] = useState(false);
    const handleClose = () => setModalShow(false);

    const handleClickLine = (lineId) => {
        if (lineId == 0) {
            setSelectedLine({letter: "",
                id: 0,
                letter: ""
            })
            setModalShow(true);
            return;
        }
        setSelectedLine(lines.filter(s => s.id == lineId)[0]);
        setModalShow(true);
    }

    const submitLineDetailForm = () => {
        var postUrl = selectedLine.id == 0 ?
            window.app.config.LINEDETAIL.LINE_CREATE_API_URL :
            window.app.config.LINEDETAIL.LINE_UPDATE_API_URL;
        selectedLine.branchId = props.branchId
        axios.post(postUrl,selectedLine, {
            headers: { "Authorization": userInfo.token }
        }).then((response) => {
            var data = response.data;
            var index = lines.findIndex(s => s.id == data.id);
            if (index == -1) {
                lines.push(data);
            } else {
                lines[index] = data;
            }
            setlines(lines);
            setTableData(prepareTableData(lines));
            toastify.success(t("toastify.Save_Success"))
        }).catch((err) => {
            console.log("error", err);
            toastify.error(t("toastify.Save_Error"))

        })
    } 
    useEffect(() => {
        axios.get(window.app.config.LINEDETAIL.LINE_LIST_API_URL  + '?branchId=' + props.branchId ?? 1, {
            headers: { "Authorization": userInfo.token }
        }).then((response) => {
            var data = response.data;
            console.log(data)
            setlines(data);
            setTableData(prepareTableData(data));
        }).catch((response) => {
            console.log("error", response);
        });
    }, [props])

    const columns = [
        {
            name: "Name",
            label: t("pages.lineSettings.lineName")
        }, {
            name: "Letter",
            label: t("pages.lineSettings.group")
        }, {
            name: "StartIndex",
            label: t("pages.lineSettings.start")
        }, {
            name: "LastIndex",
            label: t("pages.lineSettings.end")
        }, {
            name: "Actions",
            label: t("pages.lineSettings.operations"),
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <div>
                            <Button variant='btn btn-info btn-round' onClick={() => { handleClickLine(value); }}> <Icon.Pencil /> </Button>
                            <Button variant="btn btn-danger btn-round" onClick={() => { removeButtonClick({ value }); }}> <Icon.Trash /> </Button>
                        </div>
                    )
                }
            }
        }]

    const options = {
        filterType: 'textField',
        // selectableRows:false
    };
    return (
        <>
            <Button className='btn btn-info btn-round' onClick={() => { handleClickLine(0); }}> {t("pages.lineSettings.add")} </Button>
            <hr />
            <MUIDataTable
                title={t("pages.lineSettings.lineList")}
                data={tableData}
                columns={columns}
                options={options}
            />
            <Modal show={show} onHide={handleClose} animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title>{t("pages.lineSettings.line")} </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="name">
                            <Form.Label> {t("pages.lineSettings.lineName")} </Form.Label>
                            <Form.Control type="text" value={selectedLine?.name} onChange={setLineName} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="letter">
                            <Form.Label>{t("pages.lineSettings.group")} </Form.Label>
                            <Form.Control type="text" value={selectedLine?.letter} onChange={setLetter} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="startIndex">
                            <Form.Label>{t("pages.lineSettings.start")} </Form.Label>
                            <Form.Control type="number" value={selectedLine?.startIndex} onChange={setStart} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="lastIndex">
                            <Form.Label>{t("pages.lineSettings.end")} </Form.Label>
                            <Form.Control type="number" value={selectedLine?.lastIndex} onChange={setEnd} />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                    {t("pages.lineSettings.close")}
                    </Button>
                    <Button variant="primary" onClick={() => { submitLineDetailForm(); handleClose(); }}>
                    {t("pages.lineSettings.save")}
                    </Button>
                </Modal.Footer>
            </Modal>
            </>
    )
}
export default React.memo(Linesettings)
