import React, { useContext } from 'react'
import {
  CContainer,
  CHeader,
  CHeaderBrand,
  CHeaderNav,
  CHeaderToggler
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilMenu } from '@coreui/icons'
import csaLogo from '../assets/csa_logo.png';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

import { AppContext } from '../contextapi/AppProvider'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
// import HeaderDropdown from './HeaderDropdown'
import logoTim from '../assets/logo.png'
import LanguageSelection from '../components/LanguageSelection';
import classNames from 'classnames'

const Header = () => {
  const { sidebarShow, setSidebarShow, setUserInfo, setRouterData } = useContext(AppContext);
  const circleClass = classNames("base-circle", { "hide-item": !sidebarShow })

  const { t } = useTranslation();
  const navigate = useNavigate();

  const onClickLogOut = () => {
    setUserInfo({});
    setRouterData([]);
    navigate("/login")
  }
  return (
    <div>
      <div className={circleClass}></div>
      <CHeader position="sticky" className="mb-4">
        <CContainer fluid>
          <CHeaderToggler
            className="ps-1"
            onClick={() => setSidebarShow(!sidebarShow)}
          >
            <CIcon icon={cilMenu} size="lg" />
          </CHeaderToggler>
          <CHeaderBrand className="mx-auto d-md-none" to="/">
            <CIcon icon={logoTim} height={48} alt="Logo" />
          </CHeaderBrand>
          <CHeaderNav className="d-none d-md-flex me-auto">
            {/* <CNavItem>
            <CNavLink to="/dashboard" component={NavLink} activeClassName="active">
              Dashboard
            </CNavLink>
          </CNavItem>*/}
          </CHeaderNav>
          <CHeaderNav>
            {/* <CNavItem>
              <CNavLink href="#">
                <CIcon icon={cilBell} size="lg" />
              </CNavLink>
            </CNavItem>*/}
          </CHeaderNav>
          <div className='d-flex align-items-start'>
            {/* <img src={csaLogo} width={100} /> */}
          </div>
          <CHeaderNav className="ms-3">
            <div className='d-flex align-items-start'>
              <LanguageSelection />
            </div>
            <div className='d-flex align-items-start' style={{ cursor: "pointer" }}>
              <ExitToAppIcon onClick={onClickLogOut} style={{ fontSize: 25 }} />
              {/* <HeaderDropdown /> */}
            </div>
          </CHeaderNav>
        </CContainer>
      </CHeader>
    </div>
  )
}

export default Header
