import React from 'react'
import Layout from '../../Layout';
import MUIDataTable from "mui-datatables";
import axios from 'axios';
import { useState, useEffect } from "react";
import { Button, Modal, Form, Dropdown } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import { toastify } from '../../../helpers/common-helper';
import { useContext } from 'react';
import { AppContext } from '../../../contextapi/AppProvider';

const Users = () => {
    const { userInfo } = useContext(AppContext);
    const [tableData, setTableData] = useState([]);
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [roles, setRoles] = useState([]);
    const [selectedRoleName, setSelectedRoleName] = useState();

    const setUserName = (e) => {
        selectedUser.userName = e.target.value;
        setSelectedUser(selectedUser);
    }

    const setFullName = (e) => {
        selectedUser.fullName = e.target.value;
        setSelectedUser(selectedUser);
    }

    const setPassword = (e) => {
        selectedUser.password = e.target.value;
        setSelectedUser(selectedUser);
    }

    const handleChange = (roles) => {
        selectedUser.roleId = roles.split(",")[0]
        var roleName = roles.split(",")[1]
        setSelectedUser(selectedUser)
        setSelectedRoleName(roleName)
    };

    const removeButtonClick = (userId) => {
        axios.get(window.app.config.USER.DELETE_API_URL + '?userId=' + userId.value, {
            headers: { "Authorization": userInfo.token }
        }).then((response) => {
            var index = users.findIndex(s => s.id == userId.value);
            users.splice(index, 1);
            setUsers(users);
            setTableData(prepareTableData(users));
            toastify.success("Silme işlemi gerçekleşti")
        }).catch((err) => {
            console.log(err);
            toastify.error("Hata oluştu")

        })
    }

    const prepareTableData = (users) => {
        var rows = [];
        users.forEach(function (item) {
            var columns = [];
            columns.push(item.userName);
            columns.push(item.fullName);
            columns.push(item.password);
            columns.push(item.branchName)
            columns.push(item.updateDate);
            columns.push(item.insertDate);
            columns.push(item.id);
            rows.push(columns);
        })
        return rows;
    }

    const [show, setModelShow] = useState(false);
    const handleClose = () => setModelShow(false);

    const handleModalShow = (userId) => {
        if (userId == 0) {
            setSelectedUser({
                fullName: "",
                id: 0,
                password: "",
                userName: ""
            })
            setModelShow(true);
            GetUserRoles()
            return;
        } 
        setSelectedUser(users.filter(s => s.id == userId)[0]);
        setModelShow(true); 
        GetUserRoles()

    }

    const GetUserRoles =() => {

        axios.get(window.app.config.ROLES.LIST_API_URL,{
            headers: { "Authorization": userInfo.token }
        }).then((response) => { 
            var data = response.data
            setRoles(data)
        }) 

    }



    const submitUserForm = () => {
        var postUrl = selectedUser.id == 0 ?
            window.app.config.USER.CREATE_API_URL :
            window.app.config.USER.UPDATE_API_URL;
            
        axios.post(postUrl, selectedUser, {
            headers: { "Authorization": userInfo.token }
        }).then((response) => {
            var data = response.data;
            var index = users.findIndex(s => s.id === data.id);
            if (index === -1) {
                users.push(data);
            } else {
                users[index] = data;
            }
            setUsers(users);
            setTableData(prepareTableData(users));
            toastify.success("İşleminiz başarıyla kaydedildi")

        }).catch((err) => {
            console.log("error", err);
            toastify.error("Hata oluştu")

        })
    }

    useEffect(() => {
        axios.get(window.app.config.USER.LIST_API_URL, {
            headers: { "Authorization": userInfo.token }
        }).then((response) => {
            var data = response.data;
            setUsers(data);
            setTableData(prepareTableData(data));
        }).catch((response) => {
            console.log("error", response);
        });
    }, [])

    const columns = [
        {
            name: "Username",
            label: "Username"
        }, {
            name: "FullName",
            label: "Full Name"
        }, {
            name: "Password",
            label: "Password",
            options: {
                customBodyRender: (value) => {
                    return "***";
                }
            }
        },
        {
            name: "BranchName",
            label: "Branch Name"
        },
        {
            name: "LastUpdateDate",
            label: "Last Update Date"
        }, {
            name: "InsertDate",
            label: "Insert Date"
        }, {
            name: "Actions",
            label: "Actions",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <div>
                            <Button variant="primary" onClick={() => { handleModalShow(value); }}> <Icon.Pencil /> </Button>
                            <Button variant="danger" onClick={() => { removeButtonClick({ value }); }}> <Icon.Trash /> </Button>
                        </div>
                    )
                }
            }
        }]

    const options = {
        filterType: 'textField',
        selectableRows: false

    };
    return (
        <Layout>
            <Button variant="primary" onClick={() => { handleModalShow(0); }}> Create User </Button>
            <hr />
            <MUIDataTable
                title={"User List"}
                data={tableData}
                columns={columns}
                options={options}
            />
            <Modal show={show} onHide={handleClose} animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title> User </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="username">
                            <Form.Label>Username</Form.Label>
                            <Form.Control type="text" defaultValue={selectedUser?.userName} onChange={setUserName} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="fullName">
                            <Form.Label>Full Name</Form.Label>
                            <Form.Control type="text" defaultValue={selectedUser?.fullName} onChange={setFullName} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="password">
                            <Form.Label>Password</Form.Label>
                            <Form.Control type="password" defaultValue={selectedUser?.password} onChange={setPassword} />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Dropdown onSelect={handleChange} >
                                <Dropdown.Toggle size='lg' className='col-md-12' variant="secondary" id="dropdown-basic">
                                    {selectedRoleName != null && selectedRoleName != "" ? selectedRoleName : "Seçiniz"}
                                </Dropdown.Toggle>
                                <Dropdown.Menu className='col-md-12'>
                                    {roles.map((e, key) => {
                                        return <Dropdown.Item className='dropdown-menu-center' key={e.id} eventKey={[e.id , e.roleName]}>{e.roleName}</Dropdown.Item>;
                                    })}
                                </Dropdown.Menu>
                            </Dropdown>
                        </Form.Group>

                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={() => { submitUserForm(); handleClose(); }}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        </Layout>
    )
}
export default React.memo(Users)
