import React, { useContext, useState } from 'react';
import { useRef } from 'react';
import activeUserIcon from '../assets/active-user-icon.png'
import passiveUserIcon from '../assets/inactive-user-icon.png'
import footPrintIcon from '../assets/foot-print.png'
import { CanvasFunctions, RectFunctions } from '../helpers/canvas-helper';
import { Card, Col, Row } from 'react-bootstrap';
import { useEffect } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { AppContext } from '../contextapi/AppProvider';
import { ServiceHelper } from '../helpers/service-helper';
import { generateRequestFilterObjectFromQueryParamsAndDropdownData } from '../helpers/filter-helper';
import { CCard, CCardBody } from '@coreui/react';
import PowerIcon from '@mui/icons-material/Power';
import PowerOffIcon from '@mui/icons-material/PowerOff';
import { MAP_TYPE } from '../helpers/constants';
import { useTranslation } from 'react-i18next';
// const data = {
//     locations:[
//         {
//             "address": "B01-0001-10",
//             "userName": "Test 1",
//             "xPoint": 0,
//             "yPoint": 40
//         }
//     ],
//     mapUrl: mapImg,
//     activeUserIcon: activeUserIcon,
//     passiveUserIcon: passiveUserIcon
// }

let rectObject = [];
let offsetX, offsetY;

const EmployeeMapRenderer = (props) => {
    const { dropDownFilterData } = useContext(AppContext);
    const [searchParams, setSearchParams] = useSearchParams();
    const [mapData, setMapData] = useState({});
    const { t } = useTranslation();

    const navigate = useNavigate();
    const location = useLocation();
    const canvasRefs = useRef([]);
    const canvasRef = useRef(null);
    let mapImg = new Image();
    let useLoading = true;

    useEffect(() => {
        reOffset();
        window.onscroll = function (e) { reOffset(); }
        window.onresize = function (e) { reOffset(); }
    }, [])

    const reOffset = () => {
        var BB = canvasRef.current.getBoundingClientRect();
        offsetX = BB.left;
        offsetY = BB.top;
    }

    useEffect(() => {
        if (props.element.url) {
            props.setLoading(true);
            var filters = generateRequestFilterObjectFromQueryParamsAndDropdownData(searchParams, dropDownFilterData)
            var _serviceHelper = new ServiceHelper(props.element.url, filters);
            _serviceHelper._post(
                (response) => {
                    props.setLoading(false);
                    setMapData(response.data);

                },
                (err) => {
                    props.setLoading(false);
                    setMapData({});
                    console.log(err);

                })

            const intervalId = setInterval(() => {  //assign interval to a variable to clear it.
                _serviceHelper._post(
                    (response) => {
                        setMapData(response.data);

                    },
                    (err) => {
                        setMapData({});
                        console.log(err);
                    })
            }, 5000)

            return () => clearInterval(intervalId); //This is important
        }
    }, [])

    useEffect(() => {
        if (mapData && mapData.branchMaps) {
            if (searchParams.has("userName")) {
                var userName = searchParams.get("userName");

                console.log("mapData.locations", mapData.locations)
                // mapData.branchMaps.forEach(item => {
                mapImg = new Image();
                mapImg.src = mapData.branchMaps.find(p => p.type === MAP_TYPE.FOOT_PRINT)?.imgPath;
                rectObject = [];
                mapImg.onload = function () {
                    CanvasFunctions.DrawCanvasImage(canvasRef.current, this);
                    mapData.locations.forEach(item => {
                        var marker = new CanvasFunctions.Marker(footPrintIcon);
                        marker.MarkerImage.onload = function () {
                            rectObject.push(new RectFunctions.RectForMarker(item.address, item.xPoint, item.yPoint, item.dateStr, this, canvasRef.current));
                        }
                    })
                };
                // })
            }
            else {
                console.log("mapData.locations", mapData.locations)
                // mapData.branchMaps.forEach(item => {
                mapImg = new Image();
                mapImg.src = mapData.branchMaps.find(p => p.type === MAP_TYPE.EMPLOYEE)?.imgPath;
                rectObject = [];
                mapImg.onload = function () {
                    CanvasFunctions.DrawCanvasImage(canvasRef.current, this);
                    mapData.locations.forEach(item => {
                        var marker = new CanvasFunctions.Marker(item.isUserActive ? activeUserIcon : passiveUserIcon);
                        marker.MarkerImage.onload = function () {
                            rectObject.push(new RectFunctions.RectForMarker(item.address, item.xPoint, item.yPoint, item.userName?.toUpperCase(), this, canvasRef.current));
                        }
                    })
                };
                // })
            }
        }
    }, [mapData])

    const handleMouseMoveCanvas = (e) => {
        CanvasFunctions.DrawCanvasImage(canvasRef.current, mapImg);
        let mouseX = parseInt(e.clientX - offsetX);
        let mouseY = parseInt(e.clientY - offsetY);
        // Put your mousemove stuff here
        var selectedObject = null;
        for (var i = 0; i < rectObject.length; i++) {
            if (rectObject[i].isPointInside(mouseX, mouseY)) {
                selectedObject = rectObject[i];
            } else {
                rectObject[i].redraw();
            }
        }
        if (selectedObject)
            selectedObject.highlight();

    }

    const handleMouseDownCanvas = (e) => {
        //CanvasFunctions.DrawCanvasImage(canvasRef.current, mapImg);
        let mouseX = parseInt(e.clientX - offsetX);
        let mouseY = parseInt(e.clientY - offsetY);
        // Put your mousemove stuff here
        var selectedUserName = null;
        for (var i = 0; i < rectObject.length; i++) {
            if (rectObject[i].isPointInside(mouseX, mouseY)) {
                selectedUserName = rectObject[i].markerText;
            }
        }
        if (selectedUserName)
            handleOnClickUser(selectedUserName)

    }

    const handleMouseMoveUsers = (item) => {
        CanvasFunctions.DrawCanvasImage(canvasRef.current, mapImg);
        // Put your mousemove stuff here
        var selectedObject = null;
        for (var i = 0; i < rectObject.length; i++) {
            if (rectObject[i].id === item.address) {
                //rectObject[i].highlight();
                selectedObject = rectObject[i];
            } else {
                rectObject[i].redraw();
            }
        }
        if (selectedObject)
            selectedObject.highlight();

    }

    const handleOnClickUser = (userName) => {
        navigate({
            pathname: location.pathname,
            search: `?userName=${userName}`,
          });
    }

    return (
        <>
            <Row>
                <Col md={3}>
                    <CCard className='custom-card'>
                        <CCardBody>
                            <Row>
                                {
                                    searchParams.has("userName") ?
                                        <>
                                            <h5>{t(`dynamic.employeemap.labels.employee`, "Employee")}: {searchParams.get("userName")}</h5>
                                            <Col md={12}>
                                                <h6 style={{ textAlign: "center" }}>Time Period</h6>
                                                <hr />
                                                {
                                                    mapData.locations?.map(item => {
                                                        return (
                                                            <div style={{ margin: 0, fontSize: 14, cursor: "pointer" }} onMouseMove={(e) => handleMouseMoveUsers(item)}>
                                                                {item.dateStr}
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </Col>
                                        </>
                                        :
                                        <>
                                            <h5 style={{ textAlign: "center" }}>{t(`dynamic.employeemap.labels.employees`, "Employees")}</h5>
                                            <Col md={6}>
                                                <h6 style={{ textAlign: "center" }}>{t(`dynamic.employeemap.labels.active`, "Active")}</h6>
                                                <hr />
                                                {
                                                    mapData.locations?.filter(p => p.isUserActive).map(item => {
                                                        return (
                                                            <div 
                                                                style={{ margin: 0, fontSize: 14, cursor: "pointer" }} 
                                                                onMouseMove={(e) => handleMouseMoveUsers(item)}
                                                                onClick={() => handleOnClickUser(item.userName)}>
                                                                <PowerIcon style={{ color: "green" }} />
                                                                {item.userName?.toUpperCase()}
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </Col>
                                            <Col md={6}>
                                                <h6 style={{ textAlign: "center" }}>{t(`dynamic.employeemap.labels.passive`, "Passive")}</h6>
                                                <hr />
                                                {
                                                    mapData.locations?.filter(p => !p.isUserActive).map(item => {
                                                        return (
                                                            <div 
                                                                style={{ margin: 0, fontSize: 14, cursor: "pointer" }} 
                                                                onMouseMove={(e) => handleMouseMoveUsers(item)}
                                                                onClick={() => handleOnClickUser(item.userName)}>
                                                                <PowerOffIcon style={{ color: "red" }} />
                                                                {item.userName?.toUpperCase()}
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </Col>
                                        </>
                                }
                                
                            </Row>
                        </CCardBody>
                    </CCard>
                </Col>
                <Col md={9}>
                    <CCard className='custom-card'>
                        <CCardBody>
                            <Col md={12}>
                                <canvas ref={canvasRef} width="1000" height="1000" onMouseDown={handleMouseDownCanvas} onMouseMove={handleMouseMoveCanvas}></canvas>
                            </Col>
                        </CCardBody>
                    </CCard>
                </Col>
            </Row>
        </>
    );
}

export default EmployeeMapRenderer;