import { CCard, CCardBody, CCardFooter, CCardHeader, CCol, CRow } from '@coreui/react';
import React, { useState, useContext, useEffect } from 'react';
import Chart from "react-apexcharts";
import { AppContext } from '../contextapi/AppProvider';
import { useTranslation } from 'react-i18next';
import { generateRequestFilterObjectFromQueryParamsAndDropdownData } from '../helpers/filter-helper';
import { ServiceHelper } from '../helpers/service-helper';
import { useSearchParams } from 'react-router-dom';


const VerticalBarChartRenderer = (props) => {
    console.log("Chart Props", props);
    const { dropDownFilterData, currentLanguageCode } = useContext(AppContext)
    const [searchParams, setSearchParams] = useSearchParams()
    const { t } = useTranslation();
    const [chartOptions, setChartOptions] = useState({
        options: {},
        series: [],
    })

    useEffect(() => {
        let obj = { ...chartOptions };
        obj.options = {
            chart: {
                height: 430,
                type: 'bar',
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '55%',
                    endingShape: 'rounded'
                },
            },
            dataLabels: {
                enabled: false
            },
            colors: [],
            stroke: {
                show: true,
                width: 2,
                colors: ['transparent'],
            },
            fill: {
                type: 'gradient',
                gradient: {
                    shade: 'light',
                    type: "vertical",
                    shadeIntensity: 0.25,
                    inverseColors: true,
                    opacityFrom: 1,
                    opacityTo: 0.7,
                    stops: [50, 100]
                },
            },
            xaxis: {
                categories: [],
            },
            yaxis: {
                title: {
                    text: ''
                }
            },
            tooltip: {
                y: {
                    formatter: function (val) {
                        return val
                    }
                }
            }
        }

        if (props.element.url) {
            props.setLoading(true);
            var filters = generateRequestFilterObjectFromQueryParamsAndDropdownData(searchParams, dropDownFilterData)
            var _serviceHelper = new ServiceHelper(props.element.url, filters);
            _serviceHelper._post(
                (response) => {
                    obj.series = response.data.series.map(item => {
                        item.name = t(`dynamic.graphics.labels.${item.name}`, item.name.toString())
                        return item
                    });
                    obj.options.xaxis.categories = response.data.labels.map(item => {
                        return t(`dynamic.graphics.labels.${item}`, item.toString())
                    });
                    obj.options.colors = response.data.colors;
                    setChartOptions(obj)
                    props.setLoading(false);
                },
                (err) => {
                    console.log(err);

                    obj.series = [];
                    obj.options.xaxis.categories = [];
                    obj.options.colors = [];
                    setChartOptions(obj)
                    props.setLoading(false);
                })
        }
        else {
            obj.options.xaxis.categories = props.element.labels;
            obj.options.colors = props.element.colors;
            obj.series = props.element.series;

            setChartOptions(obj)  
        }
    }, [currentLanguageCode])
    return (
        <>
            <CCard className="custom-card h-100">
                {props.element.cardInfo && props.element.cardInfo.headers && props.element.cardInfo.headers.length > 0 &&
                    <CCardHeader>
                        <CRow>
                            {
                                props.element.cardInfo.headers.map((p, key) => {
                                    return <CCol key={key} md={p.column}><h6 style={p.style?.inlineStyle}>{t(`dynamic.graphics.headers.${p.text}`, p.text.toString())}</h6></CCol>
                                })
                            }
                        </CRow>
                    </CCardHeader>
                }
                <CCardBody>
                    <Chart
                        options={chartOptions.options}
                        series={chartOptions.series}
                        type="bar"
                        height={350}
                    />
                </CCardBody>
                {props.element.cardInfo && props.element.cardInfo.footers && props.element.cardInfo.footers.length > 0 &&
                    <CCardFooter>
                        <CRow>
                            {
                                props.element.cardInfo.footers.map((p, key) => {
                                    return <CCol key={key} md={p.column}><h6 style={p.style?.inlineStyle}>{t(`dynamic.graphics.footers.${p.text}`, p.text.toString())}</h6></CCol>
                                })
                            }
                        </CRow>
                    </CCardFooter>
                }
            </CCard>
        </>);
}

export default VerticalBarChartRenderer;