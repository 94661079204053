import React, { createElement } from 'react';
import * as MuiIcons from '@mui/icons-material';
import { ICON_TYPE } from '../helpers/constants';

const IconRenderer = ({ iconName, style, type, ...rest }) => {
    return (
        <>
            {
                iconName &&
                <div style={style} {...rest}>
                    {
                        type === ICON_TYPE.IMAGE ?
                            <img src={require(`../assets/icons/${iconName}.png`)} style={{height: "100%"}} />
                            :
                            createElement(MuiIcons[iconName])
                    }
                </div>
            }
        </>
    );
}


export default IconRenderer;