import { CCard, CCardBody, CCol, CRow, CCardHeader, CFormSelect, CFormTextarea, CButton, CContainer } from "@coreui/react";
import Layout from '../Layout';
import axios from "axios";
import React, { memo, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ELEMENT_TYPE, LOCAL_STORAGE_KEYS, PROJECT_DETAILS_LIST_TYPES, TASK_TEMPLATE_OBJECTS } from "../../helpers/constants";
import { AppContext } from '../../contextapi/AppProvider';
import { Card, Col, ModalBody, Row } from "react-bootstrap";
import MUIDataTable from 'mui-datatables';
import { toastify } from '../../helpers/common-helper';
import Chip from '@mui/material/Chip';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Stack from '@mui/material/Stack';
import { Modal } from 'react-bootstrap';
import TextField from '@mui/material/TextField';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import MenuItem from '@mui/material/MenuItem';
import { useRef, useMemo } from 'react';
import Avatar from '@mui/material/Avatar';
import AlertTitle from '@mui/material/AlertTitle';
import { blue, green, orange, pink } from '@mui/material/colors';
import AssignmentRoundedIcon from '@mui/icons-material/AssignmentRounded';
import { CommitRounded } from "@mui/icons-material";
import Autocomplete from '@mui/material/Autocomplete';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
const TaskManagementAdminPanel = () => {
    const { setLoading, loading } = useContext(AppContext);
    const [formData, setFormData] = useState([]);
    const { t } = useTranslation();
    const [selectedUser, setSelectedUser] = useState()
    const [message, setMessage] = useState();
    const [showEditModal, setShowEditModal] = useState(false);
    const [project, setProject] = useState([]);


    const handleModal = () => { setShowEditModal(showEditModal ? false : true); }

    useEffect(() => {
        getProject();
    }, []);

    const getProject = async () => {

        await axios.get(`${window.app.config.STATIC_NAVBAR.ATM_TOOL.GET_ALL_PROJECT}`)
            .then(response => {
                console.log(response.data, "projects");
                setProject(response.data);
            })
            .catch(err => {

                console.log(err);
                toastify.error(err);

            })
    }

    const columns = [
        {
            name: "projectName",
            label: t(`dynamic.datatable.columns.projectName`),
            options: {
                filter: true,
                sort: true,
            }
        },  
        {
            name: "isActive",
            label: t(`dynamic.datatable.columns.Status`),
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "insertDate",
            label: t(`dynamic.datatable.columns.insertDate`),
            options: {
                filter: true,
                sort: true,
            }
        }
    ];

    const options = {
        // filterType: "dropdown",
        download: false,
        filterType: "textField",
        print: false,
        selectToolbarPlacement: 'none',

    };

    const retrieveData = () => {
        setLoading(true);
        axios.post(window.app.config.TASKMANAGEMENT.TASK_ASSIGNMENT_LIST_API_URL)
            .then((res) => {
                setFormData(res.data);
                setLoading(false);
            }) // Handle the response from backend here
            .catch((err) => {
                toastify.error(err.toString());
                setFormData([]);
                setLoading(false);
            }); // Catch errors if any
    }

    const handleSendMessage = () => {
        var deviceId = selectedUser
        var userMessage = message
        setLoading(true)
        
        axios.post(`${window.app.config.TASKMANAGEMENT.TASK_INSERT_API_URL}`, { deviceId, userMessage })
            .then(response => {
                console.log(response.data)
                toastify.success(t("toastify.Save_Success"))
                setLoading(false);
                retrieveData()
            })
            .catch(err => {
                console.log(err);
                toastify.error(err.toString())
                setLoading(false);
                retrieveData()

            })
    }


    const TaskCreateModal = (props) => {

        const { setProject, getProject } = props;
        const [projectName, setProjectName] = useState(null);
        const [templateName, setTemplateName] = useState(null);
        const [taskName, setTaskName] = useState(null);
        const [taskTemplates, setTaskTemplates] = useState([...Object.values(TASK_TEMPLATE_OBJECTS)]);

        const clearInputs = () => {
            setProjectName(null);
        };

        const createProject = () => {
            if (projectName) {
                var userInfo = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER_INFO));
                var userId = userInfo.id;

                const data = {
                    projectName: projectName,
                    userId: userId,
                    isDeleted: false,
                    isActive: true
                };

                setLoading(true);
                axios.post(`${window.app.config.STATIC_NAVBAR.ATM_TOOL.CREATE_PROJECT}`, data)
                    .then(response => {
                        
                        console.log(response.data)
                        createTemplate(response.data.id);
                    })
                    .catch(err => {
                        setLoading(false);
                        console.log(err);
                        toastify.error("There is an error occurred while project create");
                    })
            }
            else{
                setLoading(false);
                toastify.error("Project name cannot be empty");
            }

        }

        const createTemplate = (projectId) => {
                var userInfo = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER_INFO));
                var userId = userInfo.id;

                const data = {
                    name: templateName,
                    advenceTaskManagementProjectId: projectId,
                    userId: userId,
                    objectTypes: taskTemplates,
                    isDeleted: false,
                    isActive: true
                };
                console.log(data, "data");

                axios.post(`${window.app.config.STATIC_NAVBAR.ATM_TOOL.CREATE_TEMPLATE}`, data)
                    .then(response => {
                        
                        setLoading(false);
                        handleModal();
                        console.log(response.data)
                        toastify.success("Process completed successfully");
                        clearInputs();
                        getProject();
                    })
                    .catch(err => {
                        debugger;
                        setLoading(false);
                        console.log(err);
                        toastify.error("There is an error occurred while project create");
                    })
        }

        return (
            <div>
                <Modal
                    show={showEditModal}
                    backdrop="static"
                    onHide={handleModal}
                    keyboard={false}

                    dialogClassName="modal-60w"
                    size={'lg'}
                >
                    <Modal.Header closeButton>
                        <Stack direction='row' alignItems="center" spacing={1}>

                            <Avatar sx={{ bgcolor: orange[700] }} >
                                <AssignmentRoundedIcon />
                            </Avatar>
                            <AlertTitle><strong>Project Create</strong></AlertTitle>
                        </Stack>
                    </Modal.Header>
                    <CContainer>

                        <CRow className="justify-content-center">
                            <CCol md={8}  >
                                <Stack marginBottom={3} >
                                    <Stack >
                                        <TextField
                                            fullWidth
                                            id="projectname"
                                            label="Project Name"
                                            variant="standard"
                                            value={projectName}
                                            //helperText={validations?.projectName}
                                            onChange={e => setProjectName(e.target.value)}
                                        />

                                    </Stack>
                                </Stack>
                            </CCol>
                        </CRow>
                        
                    </CContainer>

                    <Modal.Body>
                        <Stack direction='row' alignItems="center" spacing={1}>

                            <Avatar sx={{ bgcolor: orange[700] }} >
                                <AssignmentRoundedIcon />
                            </Avatar>
                            <AlertTitle><strong>Template Create</strong></AlertTitle>
                        </Stack>

                        <CRow className="justify-content-center">
                            <CCol md={8}  >
                                <Stack marginBottom={3} >
                                    <Stack >
                                        <TextField
                                            fullWidth
                                            id="templateName"
                                            label="Template Name"
                                            variant="standard"
                                            value={templateName}
                                            //helperText={validations?.projectName}
                                            onChange={e => setTemplateName(e.target.value)}
                                        />

                                    </Stack>
                                </Stack>
                            </CCol>

                        </CRow>
                        <ElementsButton taskTemplates={taskTemplates} setTaskTemplates={setTaskTemplates} />
                    </Modal.Body>
                    <Modal.Footer className="d-flex justify-content-center">
                        <Chip sx={{ fontSize: 15, padding: 3 }} label="Create" color="warning"
                            onClick={() => createProject()}
                            icon={<CheckCircleOutlineIcon sx={{ fontSize: 25 }} color="warning" />}
                        />
                    </Modal.Footer>
                </Modal >
            </div>
        )
    }



    const ElementsButton = (props) => {
        const { taskTemplates, setTaskTemplates } = props;

        const handleOnSelectRowElement = (element, selectedType) => {
            var cpTaskTemplates = [...taskTemplates];
            var filteredElement = cpTaskTemplates.find(p => p.Key === element.Key);
            if(!filteredElement){
                toastify.error("There is no founded element");
                return;
            }

            filteredElement.Type = selectedType;

            console.log(cpTaskTemplates);
            setTaskTemplates([...cpTaskTemplates])
        }

        const handleOnChangeTextRowElement = (element, textValue) => {
            var cpTaskTemplates = [...taskTemplates];
            var filteredElement = cpTaskTemplates.find(p => p.Key === element.Key);
            if(!filteredElement){
                toastify.error("There is no founded element");
                return;
            }

            filteredElement.Value = textValue;

            console.log(cpTaskTemplates);
            setTaskTemplates([...cpTaskTemplates])
        }

        return (<>
            <Stack direction='row' alignItems="center" spacing={1}>
                <Avatar sx={{ bgcolor: orange[700], width: 28, height: 28 }} >
                    <AssignmentRoundedIcon fontSize="small" />
                </Avatar>
                <AlertTitle><strong>Select Type</strong></AlertTitle>
            </Stack>
            <hr />
            <Row>
                {
                    Object.values(TASK_TEMPLATE_OBJECTS).map(item => {
                        return (
                            <Col md={6}>
                                <h6 className="text-center">{item.Align.toLocaleUpperCase()} Row {item.Order}</h6>
                                <CreateElements element={item} handleOnSelectRowElement={handleOnSelectRowElement} handleOnChangeTextRowElement={handleOnChangeTextRowElement} />
                            </Col>
                        )
                    })
                }
            </Row>
        </>)
    }
    
    const CreateElements = (props) => {
        const { element, handleOnSelectRowElement, handleOnChangeTextRowElement } = props;
        const [inputType, setInputType] = useState(null);


        const fields = [
            {
                value: ELEMENT_TYPE.NONE,
                label: 'None',
            },
            {
                value: ELEMENT_TYPE.TEXT,
                label: 'Text',
            },
            {
                value: ELEMENT_TYPE.USER_INPUT,
                label: 'User Input',
            },
            {
                value: ELEMENT_TYPE.DEVICE_INPUT,
                label: 'Device Input',
            }
        ];

        return (
            <>
                <Stack>

                    <Stack marginBottom={2} justifyContent="center" alignItems="center" direction="row" spacing={1} >

                        <TextField
                            sx={{
                                width: { sm: 200, md: 300 }
                            }}
                            select
                            label="Element"
                            //helperText={validations?.labelColor}
                            variant="standard"
                            value={inputType}
                            onChange={e => { handleOnSelectRowElement(element, e.target.value); setInputType(e.target.value) }}
                        >
                            {fields.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Stack>
                </Stack >
                <Stack>
                    <Stack marginBottom={2} justifyContent="center" alignItems="center" direction="row" spacing={1} >
                        <TextField
                            sx={{
                                width: { sm: 200, md: 300 }
                            }}
                            label="Element Text Value"
                            //helperText={validations?.labelColor}
                            variant="standard"
                            hidden={inputType !== ELEMENT_TYPE.TEXT}
                            //value={inputType}
                            onChange={e => { handleOnChangeTextRowElement(element, e.target.value); }}
                        >
                        </TextField>
                    </Stack>
                </Stack>
            </>
        )
    }

    return (
        <Layout>

            <CCol md={12} >

                <Stack marginBottom={3} className="d-flex justify-content-center " direction="row" spacing={2}>
                    <Chip sx={{ fontSize: 18, padding: 3, paddingLeft: 1, paddingRight: 1 }} color="warning" label="Create Project"
                        onClick={() => {
                            handleModal();
                        }}
                        icon={<AddCircleOutlineIcon sx={{ fontSize: 35 }} />}
                    />
                </Stack>
                <CCard className="custom-card bg-white rounded">
                    <CCardBody>
                        <CRow md={12}>
                            <MUIDataTable
                                title={t(`dynamic.datatable.headers.task_management`)}
                                data={project}
                                columns={columns}
                                options={options}
                            />
                        </CRow>
                    </CCardBody>
                    <TaskCreateModal setProject={setProject} getProject={getProject} />
                </CCard>
            </CCol>
        </Layout>
    )

}


export default TaskManagementAdminPanel;
