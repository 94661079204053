import classNames from 'classnames';
import React, { useContext, useEffect, useState, createElement } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { AppContext } from '../contextapi/AppProvider';
import { generateRequestFilterObjectFromQueryParamsAndDropdownData } from '../helpers/filter-helper';
import { ServiceHelper } from '../helpers/service-helper';
import IconRenderer from './IconRenderer';


const CardRenderer = (props) => {
    const { t } = useTranslation();
    const { dropDownFilterData } = useContext(AppContext);
    const [searchParams, setSearchParams] = useSearchParams();
    const [cardData, setCardData] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);

    useEffect(() => {
        if (props.element.url) {
            props.setLoading(true);
            var filters = generateRequestFilterObjectFromQueryParamsAndDropdownData(searchParams, dropDownFilterData)

            var _serviceHelper = new ServiceHelper(props.element.url, filters);
            _serviceHelper._post(
                (response) => {
                    props.setLoading(false);
                    setCardData(response.data);

                },
                (err) => {
                    props.setLoading(false);
                    setCardData([]);
                    console.log(err);

                })
        }
        else {
            setCardData(props.element.items);
        }
    }, []);

    return (
        <>
            <div className={classNames("card", "bg-c-green", "h-100", { "cursor-pointer": props.element.useModal })} onClick={() => props.element.useModal ? setModalVisible(true) : undefined}>
                <div className="card-body" style={props.element.style?.inlineStyle}>
                    <Row className='h-100'>
                        <p className="text-white custom-text">{t(`dynamic.cards.headers.${props.element.header}`, props.element.header)}</p>
                        {cardData?.map((item, index) => (
                            <>
                            
                                {/*
                                icon olayı kullanılmadığı için, bu da card tasarımını bozduğu için kaldırıldı col md 10-2 olarak ayarlıydı col md 12 olarak düzeltildi
                                 {
                                    item.style && item.style.icon && item.style.icon !== "icon" &&
                                    <Col md={2}>
                                        <IconRenderer iconName={item.style.icon} type={item.style?.type} style={{ color: "white" }} />
                                    </Col>
                                } */}
                                
                                 <Col className={index !== cardData.length - 1 ? "pb-3" : ""} md={9}>
        <p key={index} className="text-right text-white custom-text d-flex justify-content-start">
            {t(`dynamic.cards.labels.${item.text}`, item.text)}
        </p>
    </Col>
    <Col className={index !== cardData.length - 1 ? "pb-3" : ""} md={3}>
        <p key={index + 1} className="text-white custom-text d-flex justify-content-end">
            {item.value}
        </p>
    </Col>
                            </>
                        ))}
                    </Row>
                </div>
            </div>
            <Modal show={modalVisible} onHide={() => setModalVisible(false)}>
                <Modal.Header>
                    <Modal.Title>
                        {props.element.modalHeader}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        props.element.modalData?.map(item => {
                            return (
                                <>
                                    <h5>{item.text}</h5>
                                    <span>{item.value}</span>
                                </>
                            )
                        })
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => setModalVisible(false)}>Kapat</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default CardRenderer;