import React from 'react'
import Layout from '../../../Layout';
import MUIDataTable from "mui-datatables";
import axios from 'axios';
import { useState, useEffect } from "react";
import { Button, Modal, Form, Card, Col, Row } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import { toastify } from '../../../../helpers/common-helper';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import { AppContext } from '../../../../contextapi/AppProvider';

const SystemSetting = (props) => {
    const { userInfo } = useContext(AppContext);
    const [systemSettings, setSystemSettings] = useState({});
    const { t } = useTranslation();

    const handleChange = (event) => {
        const name = event.target.id;
        const value = event.target.value;
        
        setSystemSettings(values => ({ ...values, [name]: value }))
    }

    useEffect(() => {
        axios.get(window.app.config.SYSTEMSETTINGS.SYSTEMSETTINGS_LIST_API_URL + '?branchId=' + props.branchId ?? 1, {
            headers: { "Authorization": userInfo.token }
        }).then((response) => {
            var data = response.data;
            setSystemSettings(data);
            console.log(data);
        }).catch((response) => {
            console.log("error", response);
        });
    }, [props])


    const submitSystemSettingForm = () => {
        var postUrl = systemSettings.branchId == 0 ?
            window.app.config.SYSTEMSETTINGS.SYSTEMSETTINGS_CREATE_API_URL :
            window.app.config.SYSTEMSETTINGS.SYSTEMSETTINGS_UPDATE_API_URL
        console.log(systemSettings);
        
        if(systemSettings.workingHourStartTime.length < 8){
            systemSettings.workingHourStartTime = systemSettings.workingHourStartTime + ":00" 
        }
        if(systemSettings.workingHourEndTime.length < 8){
            systemSettings.workingHourEndTime = systemSettings.workingHourEndTime + ":00" 
        }

        axios.post(postUrl, systemSettings, {
            headers: { "Authorization": userInfo.token }
        }).then((response) => {
            var data = response.data;
            setSystemSettings(data);
            toastify.success("Başarılıyla kaydedildi")
        }).catch((err) => {
            // alert(err)
            console.log("error", err);
        })
    }
    return (
        <>
            <Card className='custom-card'>
                <Card.Header>{t("pages.systemSettings.System_Settings")}</Card.Header>
                <Card.Body>
                    <Row>
                        <Col className='md-8'>
                            <Form.Group as={Row} className="mb-3" controlId="activeDeviceCount">
                                <Form.Label column sm={4}>
                                {t("pages.systemSettings.Number_Of_Active_Device")}
                                </Form.Label>
                                <Col sm={6}>
                                    <Form.Control value={systemSettings.activeDeviceCount} type="number" onChange={handleChange} />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3" controlId="dailyTarget">
                                <Form.Label column sm={4}>
                                {t("pages.systemSettings.Daily_Goal")}
                                </Form.Label>
                                <Col sm={6}>
                                    <Form.Control value={systemSettings.dailyTarget} type="number" onChange={handleChange} />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3" controlId="passiveThresholdForMapDisabled">
                                <Form.Label column sm={4}>
                                {t("pages.systemSettings.Passive_Transition_Time_On_Map")}
                                </Form.Label>
                                <Col sm={6}>
                                    <Form.Control value={systemSettings.passiveThresholdForMapDisabled} type="number" onChange={handleChange} />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3" controlId="passiveThresholdForMapHidden">
                                <Form.Label column sm={4}>
                                {t("pages.systemSettings.Disapper_Time_From_The_Map")} 
                                </Form.Label>
                                <Col sm={6}>
                                    <Form.Control value={systemSettings.passiveThresholdForMapHidden} type="number" onChange={handleChange} />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3" controlId="collectingPrice">
                                <Form.Label column sm={4}>
                                {t("pages.systemSettings.Collection_Fee")}   
                                </Form.Label>
                                <Col sm={6}>
                                    <Form.Control value={systemSettings.collectingPrice} type="number" onChange={handleChange} />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3" controlId="passiveThresholdForCollecting">
                                <Form.Label column sm={4}>
                                {t("pages.systemSettings.Deactivation_Time_In_Picking")}    
                                </Form.Label>
                                <Col sm={6}>
                                    <Form.Control value={systemSettings.passiveThresholdForCollecting} type="number" onChange={handleChange} />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3" controlId="passiveThresholdForPaletleme">
                                <Form.Label column sm={4}>
                                {t("pages.systemSettings.Deactivation_Time_In_Palletizing")}    
                                </Form.Label>
                                <Col sm={6}>
                                    <Form.Control value={systemSettings.passiveThresholdForPaletleme} type="number" onChange={handleChange} />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3" controlId="passiveThresholdForPaletAlma">
                                <Form.Label column sm={4}>
                                {t("pages.systemSettings.Deactivation_Time_In_Pallet_Picking")}     
                                </Form.Label>
                                <Col sm={6}>
                                    <Form.Control value={systemSettings.passiveThresholdForPaletAlma} type="number" onChange={handleChange} />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3" controlId="numberOfActivePersonelCount">
                                <Form.Label column sm={4}>
                                {t("pages.systemSettings.Active_Personel_Count")}     
                                </Form.Label>
                                <Col sm={6}>
                                    <Form.Control value={systemSettings.numberOfActivePersonelCount} type="number" onChange={handleChange} />
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} className="mb-3" controlId="lontTimeIdleConfigrationMinute">
                                <Form.Label column sm={4}>
                                {t("pages.systemSettings.Long_Time_Idle_Configration_Minute")}       
                                </Form.Label>
                                <Col sm={6}>
                                    <Form.Control value={systemSettings.lontTimeIdleConfigrationMinute} type="number" onChange={handleChange} />
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} className="mb-3" controlId="lastActiveUserHour">
                                <Form.Label column sm={4}>
                                {t("pages.systemSettings.Last_Active_User_Hour")}       
                                </Form.Label>
                                <Col sm={6}>
                                    <Form.Control value={systemSettings.lastActiveUserHour} type="number" onChange={handleChange} />
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} className="mb-3" controlId="notReadingForMinute">
                                <Form.Label column sm={4}>
                                {t("pages.systemSettings.Unread_Minute")}      
                                </Form.Label>
                                <Col sm={6}>
                                    <Form.Control value={systemSettings.notReadingForMinute} type="number" onChange={handleChange} />
                                </Col>
                            </Form.Group>


                            <Form.Group as={Row} className="mb-3" controlId="outOfPassiveForMinute">
                                <Form.Label column sm={4}>
                                {t("pages.systemSettings.Out_Of_Passive_Minute")}      
                                </Form.Label>
                                <Col sm={6}>
                                    <Form.Control value={systemSettings.outOfPassiveForMinute} type="number" onChange={handleChange} />
                                </Col>
                            </Form.Group>


                            <Form.Group as={Row} className="mb-3" controlId="idleConfigrationMinute">
                                <Form.Label column sm={4}>
                                {t("pages.systemSettings.Idle_Configration_Minute")}      
                                </Form.Label>
                                <Col sm={6}>
                                    <Form.Control value={systemSettings.idleConfigrationMinute} type="number" onChange={handleChange} />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3" controlId="cost">
                                <Form.Label column sm={4}>
                                {t("pages.systemSettings.Cost")}      
                                </Form.Label>
                                <Col sm={6}>
                                    <Form.Control value={systemSettings.cost} type="number" step={0.01} onChange={handleChange} />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3" controlId="workingHourStartTime">
                                <Form.Label column sm={4}>
                                {t("pages.systemSettings.Shift_Start")}      
                                </Form.Label>
                                <Col sm={6}>
                                    <Form.Control value={systemSettings.workingHourStartTime} type="time" onChange={handleChange} />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3" controlId="workingHourEndTime">
                                <Form.Label column sm={4}>
                                {t("pages.systemSettings.Shift_End")}      
                                </Form.Label>
                                <Col sm={6}>
                                    <Form.Control value={systemSettings.workingHourEndTime} type="time" onChange={handleChange} />
                                </Col>
                            </Form.Group>
                        </Col>
                        <Col className='md-6'>
                            <Form.Group as={Row} className="mb-3" >
                                <Form.Label column sm={4}>
                                {t("pages.systemSettings.Daily_Heat_Value")}   
                                </Form.Label>
                            </Form.Group>

                            <Form.Group as={Row} className="mb-3" >
                                <Col sm={6}>
                                    <Form.Control value={systemSettings.firstDensityValue} disabled type="text" id='firstDensityValue' onChange={handleChange} />
                                </Col>
                                <Col sm={2}>
                                    <Form.Control value={systemSettings.firstDensityColor} type="color" id='firstDensityColor' onChange={handleChange} />
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} className="mb-3"  >
                                <Col sm={4}>
                                    <Form.Control value={systemSettings.secondDensityValue} ype="text" id='secondDensityValue' onChange={handleChange} />
                                </Col>
                                <Form.Label column sm={2}>
                                {t("pages.systemSettings.And_Over")}   
                                </Form.Label>
                                <Col sm={6}>
                                    <Form.Control value={systemSettings.secondDensityColor} type="color" id='secondDensityColor' onChange={handleChange} />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3"  >
                                <Col sm={4}>
                                    <Form.Control value={systemSettings.thirdDensityValue} type="text" id='thirdDensityValue' onChange={handleChange} />
                                </Col>
                                <Form.Label column sm={2}>
                                {t("pages.systemSettings.And_Over")}   
                                </Form.Label>
                                <Col sm={6}>
                                    <Form.Control value={systemSettings.thirdDensityColor} type="color" id='thirdDensityColor' onChange={handleChange} />
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} className="mb-3"  >
                                <Col sm={4}>
                                    <Form.Control value={systemSettings.fourthDensityValue} type="text" id='fourthDensityValue' onChange={handleChange} />
                                </Col>
                                <Form.Label column sm={2}>
                                {t("pages.systemSettings.And_Over")}   
                                </Form.Label>
                                <Col sm={6}>
                                    <Form.Control value={systemSettings.fourthDensityColor} type="color" id='fourthDensityColor' onChange={handleChange} />
                                </Col>
                            </Form.Group>

                        </Col>
                    </Row>
                    <Button className='btn-save' onClick={submitSystemSettingForm} >
                    {t("pages.systemSettings.Submit")}   
                    </Button>
                </Card.Body>
            </Card>
        </>
    )

}
export default React.memo(SystemSetting)
