import React from 'react';
import { AppContextProvider } from './contextapi/AppProvider';

import './scss/style.scss';
import './App.css';
import Router from './containers/Routes/Router';

import i18n from "i18next";
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';

i18n.use(initReactI18next)
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    supportedLngs: ["en", "tr","fr"],
    backend: {
      loadPath: '../assets/locales/{{lng}}/translation.json',
    },
    fallbackLng: "en",
    detection: {
      order: ['cookie', 'htmlTag', 'querystring', 'navigator', 'path', 'subdomain'],
      caches: ['cookie']
    },
  })

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)
const Content = React.lazy(() => import('./containers/Content'));
function App() {
  return (
    <React.Suspense fallback={loading}>
      <AppContextProvider> 
        <Router />
      </AppContextProvider>
    </React.Suspense>
  );
}

export default App;
