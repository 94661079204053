import {
    useLocation,
    useNavigate,
} from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { CCardImage, CCard, CContainer, CRow, CCol } from "@coreui/react";
import { LOCAL_STORAGE_KEYS } from "../../../helpers/constants";
import { useContext, useEffect, useState } from "react";
import { AppContext } from '../../../contextapi/AppProvider';
import axios from "axios";
import { isEmpty, pullSettingsFromLocalStorage, toastify } from '../../../helpers/common-helper';
import Layout from '../../Layout';
import { useTranslation } from "react-i18next";
import { useHotkeys } from 'react-hotkeys-hook'
import React, { Suspense } from 'react'

import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import FileDownloadIcon from '@mui/icons-material/Download';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import PropTypes from 'prop-types';
import ReactImageAnnotate from '../../../components/react-image-annotate';
import { Button, Col, Image, Row } from 'react-bootstrap';
import IconRenderer from '../../../components/IconRenderer';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function UserTabs(props) {
    const [value, setValue] = React.useState(0);
    const { sampleImages, AIImages, userImages, refreshImageWithDate, currentImage, setCurrentImage, setCurrentImageIndex, setCustomSelectedImage } = props;
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const navigate = useNavigate();

    const goBack = () => {
        navigate(-1);
    }

    useEffect(() => {
        setValue(1);
    }, [])
    return (
        <>
            <Box sx={{ borderTopRightRadius: '16px', borderTopLeftRadius: '16px', bgcolor: 'background.paper' }}>
                <Box
                    sx={{ borderBottom: 1, borderColor: 'divider' }}>

                    <Tabs initialSelectedIndex={value} value={value} onChange={handleChange} aria-label="basic tabs example">
                        <IconButton sx={{ fontSize: 35 }} href='/annotationproject'> <ArrowBackIcon fontSize="inherit" /> </IconButton>
                        <Tab label="User"  {...a11yProps(0)} />
                        <Tab label="ML Service" {...a11yProps(1)} />
                        <Tab label="Examples" {...a11yProps(2)} />
                    </Tabs>
                </Box>

                <TabPanel className="justify-content-center" justifyContent="center" value={value} index={1}>
                    <ScrollableImageTabs images={userImages} refreshImageWithDate={refreshImageWithDate} currentImage={currentImage} setCurrentImage={setCurrentImage} setCurrentImageIndex={setCurrentImageIndex} setCustomSelectedImage={setCustomSelectedImage} />
                </TabPanel>
                <TabPanel className=" justify-content-center" justifyContent="center" value={value} index={2}>
                    <ScrollableImageTabs images={AIImages} refreshImageWithDate={refreshImageWithDate} setCurrentImage={setCurrentImage} setCurrentImageIndex={setCurrentImageIndex} setCustomSelectedImage={setCustomSelectedImage} />
                </TabPanel>
                <TabPanel className="  justify-content-center" justifyContent="center" value={value} index={3}>
                    <ScrollableImageTabs images={sampleImages} refreshImageWithDate={refreshImageWithDate} setCurrentImage={setCurrentImage} setCurrentImageIndex={setCurrentImageIndex} setCustomSelectedImage={setCustomSelectedImage} />
                </TabPanel>
                <hr />
            </Box>
        </>
    );
}

const ScrollableImageTabs = (props) => {
    const { images, refreshImageWithDate, currentImage, setCurrentImage, setCurrentImageIndex, setCustomSelectedImage } = props;

    console.log(images, "ScrollableImageTabs")
    return (
        <Row>
            {
                images.length > 0
                    ?
                    (
                        images.map((item, index) => (
                            <Col md={6} className="img__labeled__thumbnail__div">
                                {
                                    item.regions && item.regions.length > 0 &&
                                    <IconRenderer iconName={"DoneOutline"} className="pe-3 img__labeled__thumbnail__icon" />
                                }
                                <Image
                                    className={item.src === currentImage && "img__selected__thumbnail "}
                                    thumbnail
                                    src={`${item.src_drawed}?${refreshImageWithDate}`}
                                    onClick={() => { setCurrentImage(item.src); setCurrentImageIndex(index); setCustomSelectedImage(index) }} />
                            </Col>
                        ))) : <div>No Images</div>
            }
        </Row>
    );
}



const MainPage = (props) => {
    const { setLoading, loading } = useContext(AppContext);
    const [images, setImages] = useState([]);
    const [userImages, setUserImages] = useState([]);
    const [AIImages, setAIImages] = useState([]);
    const [sampleImages, setSampleImages] = useState([]);
    const [currentImage, setCurrentImage] = useState(null);
    const [customSelectedImage, setCustomSelectedImage] = useState(null);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [refreshImageWithDate, setRefreshImageWithDate] = useState(Date.now);

    const { t } = useTranslation();
    const location = useLocation()
    useHotkeys('alt+s', () => console.log(currentImageIndex))
    const projectId = JSON.stringify(location.state.projectId);
    const modelId = JSON.stringify(location.state.modelId);
    const selectedModel = location.state.selectedModel;

    const prepareRequestObjectForSaveAnnotation = (e) => {
        var userInfo = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER_INFO));
        var userId = userInfo.id;
        var image = e.images[currentImageIndex];
        var canvasByTagName = document.getElementsByTagName('canvas');
        var canvas = canvasByTagName[0];
        var clientWidth = canvas.clientWidth,
            clientHeight = canvas.clientHeight;
        var fitScale = Math.max(image.pixelSize.w / (clientWidth - 20), image.pixelSize.h / (clientHeight - 20));

        var _iw = image.pixelSize.w / fitScale,
            _ih = image.pixelSize.h / fitScale;

        var userAnnotations = {
            UserAnnotationImageId: image.id,
            ImageWidth: _iw,
            ImageHeight: _ih,
            ImageName: image.name,
            ModelId: modelId.replace(/"/g, ""),
            ProjectId: projectId.replace(/"/g, ""),
            UserId: userId,
            UserAnnotationViewModels: image.regions.map(item => ({
                //id: item.id,
                userAnnotationImageId: image.id,
                xPoint: item.x,
                yPoint: item.y,
                width: item.w,
                height: item.h,
                editingLabels: true,
                highlighted: false,
                annotationStaticId: item.annotationStaticId,
                annotationLabel: item.cls,
                color: item.color,
                type: item.type
            }))
        };

        return userAnnotations;
    }

    const onSaveButton = (e, v, t) => {
        
        var settings = pullSettingsFromLocalStorage();
        if (!settings.autoSave && v === "AUTO_SAVE")
            return;

        var userAnnotations = prepareRequestObjectForSaveAnnotation(e);
        var userInfo = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER_INFO));
        setLoading(true);
        var userId = userInfo.id;
        axios.put(`${window.app.config.STATIC_NAVBAR.VISION_TOOL.UPDATE_USER_ANNOTAION_IMAGE_API_URL}`, userAnnotations)
            .then(response => {
                console.log(response.data);
                setUserImages([...e.images]);
                setLoading(false);
                setRefreshImageWithDate(Date.now)
                //toastify.success("Process completed successfully");
            })
            .catch(err => {
                console.log(err);
                toastify.error(err.toString())
                setLoading(false);
            })
    }

    const exportData = () => {

        var convertedData = images.map(item => {
            return {
                image: item.src,
                regions: item.regions.map(region => {
                    return {
                        "object-class": selectedModel.labels.findIndex(p => p.labelName === region.cls),
                        x: region.x,
                        y: region.y,
                        w: region.w,
                        h: region.h
                    }
                })
            }
        });

        const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
            JSON.stringify(convertedData)
        )}`;
        const link = document.createElement("a");
        link.href = jsonString;
        link.download = "data.json";

        link.click();
    };

    useEffect(() => {
        setLoading(true);
        if (document.readyState === 'complete') {
            setLoading(false);
            getUserAnnotation();

        } else {
            window.addEventListener('load', getUserAnnotation);
            // Remove the event listener when component unmounts
            return () => window.removeEventListener('load', getUserAnnotation);
        }
    }, [])

    const getUserAnnotation = () => {
        var userInfo = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER_INFO));

        axios.get(`${window.app.config.STATIC_NAVBAR.VISION_TOOL.GET_USER_ANNOTAION_IMAGE_API_URL + '?modelId=' + JSON.parse(modelId)}`)
            .then(response => {
                console.log(response.data, 'GET_USER_ANNOTAION_IMAGE_API_URL')
                var convertedResponse = response.data.map(item => ({
                    id: item.id,
                    src: `${window.app.config.STATIC_NAVBAR.VISION_TOOL.ANNOTATION_IMAGE_URL}/${projectId.replace(/"/g, "")}/${modelId.replace(/"/g, "")}/${userInfo.id}/${item.imageName}`,
                    src_drawed: `${window.app.config.STATIC_NAVBAR.VISION_TOOL.ANNOTATION_IMAGE_URL}/${projectId.replace(/"/g, "")}/${modelId.replace(/"/g, "")}/${userInfo.id}/drawed_${item.imageName}`,
                    name: item.imageName,
                    type: "user",
                    regions: item.userAnnotations.map(annotation => ({
                        cls: annotation.annotationLabel,
                        color: annotation.color,
                        editingLabels: true,
                        h: annotation.height,
                        highlighted: false,
                        id: annotation.id,
                        type: annotation.type,
                        w: annotation.width,
                        x: annotation.xPoint,
                        y: annotation.yPoint
                    }))
                }));
                setImages(convertedResponse)
                setUserImages(convertedResponse)
                setLoading(false);
                if (convertedResponse.length > 0) {
                    setCurrentImage(convertedResponse[0].src);
                    setCurrentImageIndex(0);
                }
            })
            .catch(err => {
                console.log(err?.response?.data?.message);
                //toastify.error(err.toString())
                toastify.error(err?.response?.data?.message)
            })
    }

    console.log(selectedModel?.labels, "selectedModel?.labels")
    return (
        <Layout >
            <Row>
                <Col md={11}></Col>
                <Col md={1}>
                    <Button className="custom-shadow-lg w-100 p-3 mb-5 rounded btn-login" onClick={exportData}><FileDownloadIcon />Export</Button>
                </Col>
                <Col md={3} style={{ maxHeight: "calc(100vh - 68px)", overflow: "auto" }}>
                    <UserTabs
                        refreshImageWithDate={refreshImageWithDate}
                        sampleImages={sampleImages}
                        AIImages={AIImages}
                        userImages={userImages}
                        currentImage={currentImage}
                        setCurrentImage={setCurrentImage}
                        setCustomSelectedImage={setCustomSelectedImage}
                        setCurrentImageIndex={setCurrentImageIndex}
                    />
                </Col>
                <Col md={9} style={{ height: "calc(100vh - 68px)", }}>
                    {
                        images.length > 0 && currentImage != null &&
                        <ReactImageAnnotate
                            hideClone
                            showCrosshairs
                            selectedImage={currentImage}
                            customSelectedImage={customSelectedImage}
                            onExit={onSaveButton}
                            onNextImage={(annotatorState) => {
                                let currentImageIndex = annotatorState.selectedImage;
                                if (currentImageIndex != null && currentImageIndex !== annotatorState.images.length - 1) {
                                    onSaveButton(annotatorState)
                                    let nextImage = annotatorState.images[currentImageIndex + 1];
                                    setCurrentImageIndex(currentImageIndex + 1);
                                    setCurrentImage(nextImage.src);
                                }
                            }}
                            onPrevImage={(annotatorState) => {
                                let currentImageIndex = annotatorState.selectedImage;
                                if (currentImageIndex !== null && currentImageIndex !== 0) {
                                    onSaveButton(annotatorState)
                                    let prevImage = annotatorState.images[currentImageIndex - 1];
                                    setCurrentImageIndex(currentImageIndex - 1);
                                    setCurrentImage(prevImage.src)
                                }
                            }}
                            enabledTools={["select", "create-box"]}
                            regionClsList={selectedModel?.labels.map(p => p.labelName)}
                            regionClsColorList={selectedModel?.labels}
                            regionTagList={[]}
                            images={images}
                        />

                    }
                </Col>
            </Row>
        </Layout>
    )
}
export default MainPage;




