import axios from 'axios';
import React, { createContext, useState, useEffect } from 'react';
import { LOCAL_STORAGE_KEYS } from '../helpers/constants';
import useLocalStorage from '../hooks/useLocalStorage';

export const AppContext = createContext({});

export const AppContextProvider = ({ children }) => {
    const [dropDownFilterData, setDropDownFilterData] = useState([]);
    const [routerData, setRouterData] = useLocalStorage(LOCAL_STORAGE_KEYS.ROUTER_DATA, []);
    const [userInfo, setUserInfo] = useLocalStorage(LOCAL_STORAGE_KEYS.USER_INFO, {});
    const [isUserAuth, setIsUserAuth] = useLocalStorage(LOCAL_STORAGE_KEYS.IS_USER_AUTH, false);
    const [loading, setLoading] = useState(false);
    const [currentLanguageCode, setCurrentLanguageCode] = useState("en");
    const [formData, setFormData] = useState([]);
    const [imuValue, setImuValue] = useState(null);




    useEffect(() => {
        if (!userInfo.isUserAuth || (userInfo.isUserAuth && userInfo.isUserAuth === "false"))
            return;

        if (routerData && routerData.length > 0)
            return;

        axios.get(`${window.app.config.BASE_API_URL}/dashboardpage`,
            {
                headers: { 'Authorization': userInfo?.token }
            })
            .then((response) => {
                setRouterData(response.data)
                setLoading(false)
            })
            .catch((err) => {
                setRouterData([])
                console.log(err);
                setLoading(false)
            })
    }, [userInfo])
    const [sidebarShow, setSidebarShow] = useState(true);
    const [sidebarUnfoldable, setSidebarUnfoldable] = useState(false);
    return (
        <AppContext.Provider value={{
            routerData, setRouterData,
            userInfo, setUserInfo,
            isUserAuth, setIsUserAuth,
            formData, setFormData,
            loading, setLoading,
            sidebarShow, setSidebarShow,
            sidebarUnfoldable, setSidebarUnfoldable,
            dropDownFilterData, setDropDownFilterData,
            currentLanguageCode, setCurrentLanguageCode,
            imuValue, setImuValue
        }}>
            {children}
        </AppContext.Provider>
    )
}